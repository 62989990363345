import { css } from "styled-components";

const styles = {
  container: () => css`
    position: relative;
    background: linear-gradient(to bottom, white 50%, #00326c 50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  `,

  icon: () => css`
    position: absolute;
    pointer-events: none;
    left: 0px;
    transform: translateX(-50%);
  `,
  texto: () => css`
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `,
  tel: () => css`
    margin-right: 5px;
    @media (max-width: 1024px) {
      margin: 0;
    }
    text-decoration: none;
  `,
  whatsapp: () => css`
    position: fixed;
    text-decoration: none;
    z-index: 15;
    margin-top: 5px;
    right: 5vw;
    text-align: left;
    font: normal normal bold 17px/26px Nutmeg;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: #57bbff;
    border-radius: 24px;
    opacity: 1;
    padding: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
    @media (max-width: 1600px) {
      right: 3vw;
    }
    @media (max-width: 1200px) {
      right: 0vw;
    }
    @media (max-width: 1024px) {
      display: none;
    }
  `,
  whatsappShort: () => css`
    cursor: pointer;
    margin-top: 5px;
    position: fixed;
    z-index: 15;
    right: 5vw;
    text-align: left;
    font: normal normal bold 17px/26px Nutmeg;
    letter-spacing: 0px;
    color: #ffffff;
    opacity: 1;
    background-color: #57bbff;
    border-radius: 24px;
    opacity: 1;
    padding: 5px;
    display: flex;
    align-items: center;
    @media (min-width: 1025px) {
      display: none;
    }
  `,
};

export default styles;
