import { css } from 'styled-components'

const styles = {
  container: () => css`
    width: 100%;
    display: flex;
    flex-direction: row;
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #bac0c3;
    padding: 1em;
    opacity: 1;
  `,
  subcontainer: () => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #bac0c3;
    opacity: 1;
    @media (max-width: 700px) {
      padding-left: 2em;
    }
  `,
  icon: () => css`
    width: 1em;
    height: 1em;
    margin-right:0.5em;
  `,
  form: () => css`
    text-align: left;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    flex-direction: row;
    > * {
      width: 20%;
    }
    @media (max-width: 700px) {
      flex-direction: column;
      > * {
        width: 90%;
      }
    }
    @media (min-width: 701px) and (max-width: 1200px) {
      flex-direction: row;
      > * {
        width: 45%;
      }
    }
  `,
  fullRow: () => css`
    text-align: left;
    display: flex;
    flex-direction: column;
    > * {
      width: 100%;
    }
  `,
  title: () => css`
    width: 100%;
    text-align: left;
    font-size: 2em;
    color: #00326c;
    margin: 0;
  `,
  subtitle: () => css`
    width: 100%;
    text-align: left;
    font-size: 1.4em;
    color: #37aefd;
    margin: 0;
    margin-top: 2em;
  `,
  text: () => css`
    margin: 0;
    text-align: left;
    margin-top: 2.5em;
    margin-bottom: 2.5em;
    font-weight: normal;
    font-family: 'Nutmeg Book';
    width: 60%;
    color: #878795;
    @media (max-width: 700px) {
      width: 90%;
    }
  `,

  caption: () => css`
    display: flex;
    justify-content: center;
    height: 1.25em;
  `,
  textbox: () => css`
    border-radius: 4px;
    color: #a6a6af;
    padding-left: 1.5em;
    :disabled {
      background-color: #eaedef;
    }
  `,
  select: () => css``,
  option: () => css``
}

export default styles
