import React from 'react';
import styled from 'styled-components';
import Styles from './IconsRow.styles';

const Container = styled.div`${Styles.container}`;
const Icon = styled.img`${Styles.icon}`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const IconsRow = ({color, icons, flex, marginSides, isColumn}) => {
    return (
        <Container color={color} >
            {
                icons.map((icon,idx)=>{
                    return <Icon key={`iconrow-${idx}`} src={icon.src} alt={icon.alt} maxWidth={icon.maxWidth} flex={flex} marginSides={marginSides} />
                })
            }
        </Container>
    );
};

IconsRow.propTypes = {
};

export default IconsRow;
