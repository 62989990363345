import "./tooltip.css";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Styles from "./CurpForm.styles";
import TextCurp from "../../components/TextCurp";
import PrimaryButton from "../../components/PrimaryButton";
import { HomeRadioInput, LargeSelectInput } from "../../components/FormElement";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import CampaignData from "../../components/CampaignData";
import SecondaryButton from "../../components/SecondaryButton";
import Modal from "../../components/Modal";
import ModalVideo from "../../components/ModalVideo/ModalVideo";

import ActiveCampaigns from "../ActiveCampaigns";
import protector from "../../assets/images/5a7.svg";
import cinco from "../../assets/images/5mas.png";
import doce from "../../assets/images/12mas.png";
import quince from "../../assets/images/15mas.png";
import dieciocho from "../../assets/images/18mas.png";
import treinta from "../../assets/images/30mas.png";
import cuarenta from "../../assets/images/40mas.png";
import cincuenta from "../../assets/images/50mas.png";
import sesenta from "../../assets/images/60mas.png";
import embarazada from "../../assets/images/embarazada.png";
import influenza from "../../assets/images/ico_influenza.png";
import docentes from "../../assets/images/ico_docentes.png";
import salud from "../../assets/images/ico_salud.png";
import informacion from "../../assets/images/informacion.svg";
import calendario from "../../assets/images/calendar.svg";
import institucionalizados from "../../assets/images/institucionalizados.png";
import cancer from "../../assets/images/personas-cancer.png";
import embarazadas_vih from "../../assets/images/embarazadas_vih.png";
import comorbilidades from "../../assets/images/personas-comorbilidades.png";

import comorbilidadData from "../../data/comorbilidades";

const Container = styled.div`
  ${Styles.container}
`;
const ButtonContainer = styled.div`
  ${Styles.buttonContainer}
`;
const StepContainer = styled.div`
  ${Styles.stepContainer}
`;
const Title = styled.h3`
  ${Styles.header}
`;
const Label = styled.span`
  ${Styles.label}
`;

const TitleText = styled.h3`
  ${Styles.titleText}
`;

const TitleSpan = styled.span`
  ${Styles.titleText}
`;
const TitleBlue = styled.span`
  ${Styles.blueHeader}
`;

const BottomText = styled.span`
  ${Styles.bottom}
`;

const CurpText = styled.span`
  ${Styles.curpText}
`;
const ErrorText = styled.span`
  ${Styles.errortext}
`;
const Link = styled.a`
  ${Styles.link}
`;
const Form = styled.div`
  ${Styles.form}
`;

const FormCurp = styled.div`
  ${Styles.formVacunarme}
`;
const Divider = styled.div`
  ${Styles.divider}
`;
const Dosis = styled.span`
  ${Styles.dosis}
`;

const Item = styled.div`
  ${Styles.card}
`;

const IconContainer = styled.div`
  ${Styles.iconContainer}
`;
const Icon = styled.img`
  ${Styles.icon}
`;
const SubTitle = styled.b`
  ${Styles.subtitle}
`;
const Text = styled.p`
  ${Styles.age}
`;

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};
/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const CurpForm = () => {
  const { height, width } = useWindowDimensions();
  const [curp, setCurp] = useState("");
  const [numDosis, setDosis] = useState("");
  const [municipio, setMunicipio] = useState("");
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingMunicipio, setLoadingMunicipio] = useState(false);
  const [loadingDosis, setLoadingDosis] = useState(false);
  const [loadingCampaign, setLoadingCampaign] = useState(false);

  const [serviceAvailable, setServiceAvailable] = useState(true);
  const [loadingService, setLoadingService] = useState(true);
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState({});
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");

  const [numDosisOptions, setNumDosisOptions] = useState([
    {
      value: 1,
      caption: "Primera dosis",
    },
    {
      value: 2,
      caption: "Segunda dosis",
    },
    {
      value: 3,
      caption: "Dosis de refuerzo",
    },
    {
      value: 4,
      caption: "Segundo refuerzo",
    }
  ]);

  const initialNumDosis = [
    {
      value: 1,
      caption: "Primera dosis",
    },
    {
      value: 2,
      caption: "Segunda dosis",
    },
    {
      value: 3,
      caption: "Dosis de refuerzo",
    },
    {
      value: 4,
      caption: "Segundo refuerzo",
    },    
  ];

  const [modalTitle, setModalTitle] = useState("Lo sentimos");
  const [modalText, setModalText] = useState("");
  const [showModal, setShowModal] = useState("");
  const [showVideo, setShowVideo] = useState("");

  const showInformation = (campaign) => {
    if (!comorbilidadData[campaign]) return;
    const mText = <ul>
      {
        comorbilidadData[campaign].comorbilidades.map((comorbilidad, index) => {return <li key={`comorbilidad-${campaign}-${index}`}>{comorbilidad}</li>})
      }
    </ul>
    setModalTitle(comorbilidadData[campaign].title);
    setModalText(mText);
    setShowModal(true);
  }

  const [images, setimages] = useState({
    0: influenza,
    5: cinco,
    12: doce,
    15: quince,
    18: dieciocho,
    30: treinta,
    40: cuarenta,
    50: cincuenta,
    53: institucionalizados,
    54: cancer,
    55: embarazadas_vih,
    56: comorbilidades,
    60: sesenta,
  });

  const options = { month: "long", day: "numeric" };
  useEffect(() => {
    setLoadingService(false);
  }, []);

  const [municipios, setMunicipios] = useState([
    {
      value: "",
      caption: "",
      selected: true,
    },
    {
      value: "Guadalajara",
      caption: "Guadalajara",
      selected: false,
    },
    {
      value: "El Salto",
      caption: "El Salto",
      selected: false,
    },
    {
      value: "Tlajomulco de Zúñiga",
      caption: "Tlajomulco de Zúñiga",
      selected: false,
    },
    {
      value: "San Pedro Tlaquepaque",
      caption: "San Pedro Tlaquepaque",
      selected: false,
    },
    {
      value: "Tonalá",
      caption: "Tonalá",
      selected: false,
    },
    {
      value: "Zapopan",
      caption: "Zapopan",
      selected: false,
    },
  ]);

  const loadCurp = () => {
    window.grecaptcha.ready(() => {
      setLoading(true);
      window.grecaptcha
        .execute(SITE_KEY, { action: "submit" })
        .then((token) => {
          const curpPromise = fetch(
            `${process.env.REACT_APP_API_URL}/registro/getInfoByCurpAndCp?curp=${curp}&token=${token}`
          )
            .then((res) => {
              if (!res.ok) {
                throw res;
              }
              return res.json();
            })
            .then(({ data, modulos, campaign, campaigns, municipios }) => {
              window.localStorage.setItem("curp", JSON.stringify(curp));

              if (data.cita) {
                data.cita.fecha_aplicacion = data.cita.fecha_cita.substring(
                  0,
                  10
                );
                if (
                  data.cita.fecha_aplicacion < new Date().toISOString().substring(0,10)
                ) {
                  const segundasDosis = campaigns.filter((campaign) => {
                    return (
                      campaign.modulosVacunacion && campaign.modulosVacunacion.length > 0
                    );
                  });
                  if (segundasDosis.length === 0) {
                    setModalText(
                      "Por el momento no hay citas disponibles para tu rango de edad o se han agotado."
                    );
                    setShowModal(true);
                    setLoading(false);
                    return;
                  }
                  data.cita.primerApellido = data.cita.primer_apellido;
                  data.cita.segundoApellido = data.cita.segundo_apellido;
                  data.cita.fechaNacimiento = data.cita.fecha_nacimiento;
                  data.cita.estadoNacNombre = data.cita.entidad_de_nacimiento;
                  data.cita.fecha_aplicacion = data.cita.fecha_cita.substring(
                    0,
                    10
                  );
                  window.localStorage.setItem(
                    "user",
                    JSON.stringify(data.cita)
                  );
                  window.localStorage.setItem(
                    "campaign",
                    JSON.stringify(campaign)
                  );
                  window.localStorage.setItem(
                    "municipios",
                    JSON.stringify(municipios)
                  );

                  setUser({
                    ...data.cita,
                    selectedMunicipio: data.cita.municipio,
                    num_dosis: data.cita.num_dosis,
                    cp: "",
                  });
                  setMunicipios([
                    {value:"", caption:""},
                    ...municipios.map((curMunicipio) => {
                      return {
                        value: curMunicipio,
                        caption: curMunicipio,
                        selected: false
                      }
                    }
                  )]);
                  window.localStorage.setItem("cp", "");
                  setMunicipio(data.cita.municipio);
                  setDosis(segundasDosis[0].num_dosis);
                  setCampaigns(segundasDosis);
                  setLoading(false);
                  setStep(2);
                  return;
                } else {
                  window.localStorage.setItem(
                    "appointment",
                    JSON.stringify(data.cita)
                  );
                  window.localStorage.setItem(
                    "validation",
                    JSON.stringify(data.validacion)
                  );
                  window.localStorage.setItem(
                    "userRegister",
                    JSON.stringify({ curp })
                  );
                  window.location.href = `${process.env.REACT_APP_URL}/appointment`;
                  return;
                }
              }
              if (data.error) {
                if(data.error_code && data.error_code == '1001'){
                  setModalTitle('Felicidades');
                }
                setModalText(data.error_descripcion);
                setShowModal(true);
                setLoading(false);
                return;
              }
              if (!modulos || modulos.error) {
                if (modulos.error_descripcion) {
                  setModalText(modulos.error_descripcion);
                } else {
                  setModalText(
                    "Ocurrió un error verificando sus datos. Por favor intente de nuevo más tarde."
                  );
                }
                setShowModal(true);
                setLoading(false);
                return;
              }
              if (modulos.length === 0) {
                setModalText(
                  "Por el momento no hay citas disponibles para tu rango de edad o se han agotado."
                );
                setShowModal(true);
                setLoading(false);
                return;
              }
              if (!campaigns || campaigns.length === 0) {
                setModalText(
                  "Por el momento no hay citas disponibles para tu rango de edad o se han agotado."
                );
                setShowModal(true);
                setLoading(false);
                return;
              }
              window.localStorage.setItem("user", JSON.stringify(data.data));
              window.localStorage.setItem("campaign", JSON.stringify(campaign));
              window.localStorage.setItem(
                "municipios",
                JSON.stringify(municipios)
              );

              setMunicipios([
                {value:"", caption:""},
                ...municipios.map((curMunicipio) => {
                  return {
                    value: curMunicipio,
                    caption: curMunicipio,
                    selected: false
                  }
                }
              )]);
              setUser({
                ...data.data,
                selectedMunicipio: municipio,
                num_dosis: numDosis,
                cp: "",
              });
              window.localStorage.setItem("cp", "");
              setCampaigns(campaigns);
              setLoading(false);
              setStep(2);
            })
            .catch((err) => {
              console.error(err);
              setModalText(
                "Ocurrió un error verificando sus datos. Por favor intente de nuevo más tarde."
              );
              setShowModal(true);
              setLoading(false);
            });
        });
    });
  };

  const handleModal = (close) => {
    if (close) {
      setModalTitle('Lo sentimos');
      setShowModal(false);
      return;
    }
    window.location.href = `${process.env.REACT_APP_URL}/`;
    return;
  };

  const handleVideo = (close) => {
    if (close) {
      setShowVideo(false);
      return;
    }
    window.location.href = `${process.env.REACT_APP_URL}/`;
    return;
  };

  useEffect(() => {
    if (loadingMunicipio) {
      setSelectedMunicipio();
    }
  }, [loadingMunicipio]);

  useEffect(() => {
    if (loadingDosis) {
      setSelectedDosis();
    }
  }, [loadingDosis]);

  useEffect(() => {
    if (loadingCampaign) {
      moveToRegister();
    }
  }, [loadingCampaign]);

  const setSelectedMunicipio = () => {
    const filteredCampaigns = campaigns.filter((campaign) => {
      return campaign.municipios && campaign.municipios.includes(municipio);
    });
    if (filteredCampaigns.length === 0) {
      setModalText(
        "Este municipio no cuenta con alguna campaña activa con dosis disponibles. Favor de mantenerte informado a través de medios oficiales."
      );
      setShowModal(true);
      setLoadingMunicipio(false);
      return;
    } else {
      const dosis = filteredCampaigns.map((campaign) => {
        return campaign.num_dosis;
      });
      console.log(dosis);
      const newDosisSelections = initialNumDosis.map((dosisData) => {
        return {
          ...dosisData,
          enabled: dosis.includes(dosisData.value),
        };
      });
      const enabledDosisSelections = newDosisSelections.filter(
        (dosisSelection) => dosisSelection.enabled
      );
      setNumDosisOptions(newDosisSelections);
      if (enabledDosisSelections.length === 1) {
        setDosis(enabledDosisSelections[0].value);
      }
      setSelectedCampaigns(filteredCampaigns);
      setLoadingMunicipio(false);
      setStep(3);
    }
  };

  const setSelectedDosis = () => {
    const filteredCampaigns = selectedCampaigns.filter((campaign) => {
      return campaign.num_dosis == numDosis;
    });
    if (filteredCampaigns.length === 0) {
      setModalText(
        "Por el momento no hay citas disponibles para tu rango de edad o se han agotado."
      );
      setShowModal(true);
      setLoadingDosis(false);
      return;
    }
    if (filteredCampaigns.length === 1) {
      window.localStorage.setItem(
        "user",
        JSON.stringify({
          ...user,
          selectedMunicipio: municipio,
          num_dosis: numDosis,
          cp: "",
        })
      );
      window.localStorage.setItem(
        "campaign",
        JSON.stringify(filteredCampaigns[0])
      );
      setSelectedCampaign(filteredCampaigns[0]);
    }
    setSelectedCampaigns(filteredCampaigns);
    setLoadingDosis(false);
    setStep(4);
  };

  const moveToRegister = () => {
    window.localStorage.setItem(
      "user",
      JSON.stringify({
        ...user,
        selectedMunicipio: municipio,
        num_dosis: numDosis,
        cp: "",
      })
    );
    window.localStorage.setItem("campaign", JSON.stringify(selectedCampaign));
    window.location.href = `${process.env.REACT_APP_URL}/register`;
    return;
  };

  let FormComponent = <></>;
  if (!loadingService) {
    if (serviceAvailable && error) {
      FormComponent = (
        <Form>
          <ErrorText color={"#676D72"}>{error}</ErrorText>
        </Form>
      );
    }
    if (serviceAvailable && !error) {
      if (loading && step === 1)
        FormComponent = (
          <StepContainer>
            <FormCurp>
              <TextCurp value={curp} onChange={setCurp} />
              <ButtonContainer>
                <PrimaryButton
                  className="wait"
                  disabled={curp.length !== 18 || loading}
                >
                  <FontAwesomeIcon icon={faCircleNotch} spin color="white" />
                  &nbsp;Buscando ...
                </PrimaryButton>
              </ButtonContainer>
            </FormCurp>
          </StepContainer>
        );
      else if (step === 1) {
        FormComponent = (
          <StepContainer>
            <FormCurp>
              <TextCurp value={curp} onChange={setCurp} />
              <ButtonContainer>
                <PrimaryButton
                  disabled={curp.length !== 18 || loading}
                  onClick={loadCurp}
                >
                  Acepto / Continuar{" "}
                </PrimaryButton>
              </ButtonContainer>
            </FormCurp>
          </StepContainer>
        );
      } else if (step === 2) {
        FormComponent = (
          <Form>
            <Label>Municipio</Label>
            <LargeSelectInput
              options={municipios}
              onChange={(evt) => {
                setMunicipio(evt.target.value);
              }}
              selected={municipio}
            />
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryButton
                disabled={loadingDosis}
                onClick={() => {
                  setStep(1);
                }}
              >
                {" "}
                Regresar{" "}
              </SecondaryButton>
              <PrimaryButton
                style={{ marginLeft: "2em" }}
                disabled={municipio.length == 0 || loadingMunicipio}
                onClick={() => {
                  setLoadingMunicipio(true);
                }}
              >
                {" "}
                Continuar{" "}
              </PrimaryButton>
            </div>
          </Form>
        );
      } else if (step === 4) {
        FormComponent = (
          <Form style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: width > 780 ? "row" : "column",
                alignItems: "center",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              {numDosisOptions.map((dosis) => {
                return (
                  <>
                    <div
                      style={{
                        cursor: "pointer",
                        width: width > 780 ? "40%" : "80%",
                        fontSize: "1.125em",
                        marginTop: "1em",
                        borderRadius: "25px",
                        display: "flex",
                        flexDirection: "column",
                        boxSizing: "border-box",
                        transition:
                          "border-color 0.3s linear, height 0.3s linear",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        border:
                          numDosis === dosis.value
                            ? "4px solid #37AEFD"
                            : dosis.enabled
                            ? "4px solid transparent"
                            : "4px solid #F2F2F2",
                        padding: "1em",
                        background: dosis.enabled
                          ? "#FFFFFF 0% 0% no-repeat padding-box"
                          : "#F2F2F2 0% 0% no-repeat padding-box",
                        boxShadow: "0px 3px 12px #00000029",
                      }}
                      key={`campaign-${dosis.value}`}
                      onClick={() => {
                        if (!dosis.enabled) {
                          return;
                        }
                        setDosis(dosis.value);
                      }}
                    >
                      <span
                        style={{
                          width: "1em",
                          padding: "0.5em",
                          fontWeight: "bolder",
                          color: "white",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          height: "1em",
                          fontSize: "1em",
                          background: dosis.enabled
                            ? "#37AEFE 0% 0% no-repeat padding-box"
                            : "#7E7E7E 0% 0% no-repeat padding-box",
                          borderRadius: "50%",
                        }}
                      >
                        {dosis.value < 3 ? dosis.value : 'R'}
                      </span>
                      <span
                        style={{
                          color: dosis.enabled ? "#00326C" : "#646464",
                          marginTop: "0.5em",
                          fontWeight: "bolder",
                          fontSize: "30px",
                        }}
                      >
                        {dosis.caption}
                      </span>
                      <span
                        style={{
                          fontSize: "18px",
                          color: "#646464",
                          opacity: 1,
                        }}
                      >
                        {!dosis.enabled
                          ? "No disponible por el momento"
                          : dosis.value == 2
                          ? "Si ya tienes una dosis previa"
                          : dosis.value == 3
                          ? "Si ya tienes tu esquema completo"
                          : dosis.value == 4
                          ? "Si ya tienes un refuerzo previo"
                          :"Si no te han vacunado"}
                      </span>
                    </div>
                  </>
                );
              })}
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryButton
                disabled={loadingDosis}
                onClick={() => {
                  setStep(2);
                }}
              >
                {" "}
                Regresar{" "}
              </SecondaryButton>
              <PrimaryButton
                style={{ marginLeft: "2em" }}
                disabled={!numDosis || loadingDosis}
                onClick={() => {
                  setLoadingDosis(true);
                }}
              >
                {" "}
                Continuar{" "}
              </PrimaryButton>
            </div>
          </Form>
        );
      } else if (step === 3) {
        FormComponent = (
          <Form style={{ width: "100%" }}>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                flexWrap: "wrap",
              }}
            >
              {selectedCampaigns.map((campaign) => {
                let dosisText = "Primera dosis";
                if(campaign.id === 17){
                  dosisText = 'Dosis única';
                }else if(campaign.num_dosis === 2){
                  dosisText = 'Segunda dosis';
                }else if(campaign.num_dosis === 3){
                  dosisText = 'Dosis de refuerzo'
                }else if(campaign.num_dosis === 4){
                  dosisText = 'Segundo Refuerzo'
                }
                return (
                  <div
                    style={{
                      cursor: "pointer",
                      marginTop: "1em",
                      borderRadius: "25px",
                      boxSizing: "border-box",
                      transition: "border-color 0.3s linear",
                      height: "220px",
                      maxWidth: "405px",
                      display: "flex",
                      textAlign: "left",
                      alignItems: "center",
                      justifyContent: "center",
                      border:
                        selectedCampaign.id === campaign.id
                          ? "4px solid #37AEFD"
                          : "4px solid transparent",
                      padding: "1em",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 3px 12px #00000029",
                    }}
                    key={`campaign-${campaign.id}`}
                    onClick={() => {
                      setSelectedCampaign(campaign);
                    }}
                  >
                    <Item>
                      <IconContainer>
                        <Icon
                            src={
                              campaign.embarazada
                                  ? embarazada
                                  : [22,43].includes(campaign.id) ? docentes
                                      : campaign.id == 23 ? salud
                                          : [53,54,55,56].includes(campaign.id) ? images[campaign.id]
                                              : images[campaign.edad_minima]
                            }
                        />
                      </IconContainer>
                      <SubTitle size={18}>
                        {campaign.descripcion
                            ?.replace(/\([^()]*\)/g, "")
                            .replace(/'/g, "")
                            .replace(/\(|\)/g, "")}
                      </SubTitle>
                      <Text
                          size={18}
                          color="#37AEFE"
                          styleFont="italic"
                          weight={600}
                      >
                      </Text>
                      {
                          campaign?.id === 17 &&
                          (
                              <>
                                <Text
                                    size={16}
                                    color="#FF6C00"
                                    weight={600}
                                >
                                  - Embarazadas
                                </Text>
                                <Text
                                    size={16}
                                    color="#FF6C00"
                                    weight={600}
                                >
                                  - Niños de 6 meses a 5 años
                                </Text>
                                <Text
                                    size={16}
                                    color="#FF6C00"
                                    weight={600}
                                >
                                  - Personas mayores de 60 años
                                </Text>
                                <Text
                                    size={16}
                                    color="#FF6C00"
                                    weight={600}
                                >
                                  - Pacientes con enfermedades crónicas
                                </Text>
                              </>
                          )
                      }
                      {
                          [53,54,55,56].includes(campaign.id) &&
                          <div
                            onClick={()=>{showInformation(campaign.id)}}
                            style={{cursor: 'pointer'}}
                          >
                          <Text
                              size={15}
                              color="#FF6C00"
                              styleFont="italic"
                              weight={600}
                              style={{marginTop:'1em', marginBottom:'1em'}}
                          >
                            <Icon src={informacion} style={{width:'16px', height:'16px'}}/>
                            ¿Quiénes se incluyen?
                          </Text>
                          </div>
                      }

                      {campaign?.biologico && (
                          <Text
                              size={15}
                              color="#37AEFE"
                              weight={600}
                              style={{ textTransform: "uppercase" }}
                          >
                            <Icon src={calendario} style={{width:'16px', height:'16px'}}/>
                            {campaign.biologico}
                          </Text>
                      )}
                      {campaign?.num_dosis >= 2 && (
                          <Text
                              size={15}
                              color="#FF6C00"
                              styleFont="italic"
                              weight={600}
                          >
                            {campaign.num_dosis === 2 ? 'Primera' : 'Última'} dosis aplicada antes del{" "}
                            {new Date(
                                campaign.max_prev_dose_date + "T00:00:00-06:00"
                            ).toLocaleDateString("es-MX", options)}
                          </Text>
                      )}
                    </Item>
                  </div>
                );
              })}
            </div>
            <div
              style={{
                marginTop: "2em",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <SecondaryButton
                disabled={loadingCampaign}
                onClick={() => {
                  setStep(2);
                }}
              >
                {" "}
                Regresar{" "}
              </SecondaryButton>

              <PrimaryButton
                style={{ marginLeft: "2em" }}
                disabled={!selectedCampaign.id || loadingCampaign}
                onClick={() => {
                  setLoadingCampaign(true);
                }}
              >
                {" "}
                Continuar{" "}
              </PrimaryButton>
            </div>
          </Form>
        );
      }
    }
    if (!serviceAvailable) {
      FormComponent = (
        <Form>
          <ErrorText color={"#676D72"}>{error}</ErrorText>
        </Form>
      );
    }
  }

  return (
    <Container style={{ zIndex: "auto" }}>
      <ActiveCampaigns step={step} setModalText={setModalText} setShowModal={setShowModal} setModalTitle={setModalTitle} />
      {!error && !loadingService && step === 1 && (
        <>
          <StepContainer>
            <TitleBlue color={"#37AEFE"} size={30}>
              Comenzar ahora
            </TitleBlue>
            <CurpText>
              Previo a ingresar tus datos personales consulta nuestro <br/> <a target="_blank" rel="noopener noreferrer" href="https://transparenciasitgej.jalisco.gob.mx/api/api/banco_archivos/131542/downloadWeb">aviso de privacidad</a>
            </CurpText>
            <br/>
            <CurpText>
              Ingresa tu CURP para agendar una cita.
            </CurpText>
          </StepContainer>
        </>
      )}
      {!error && !loadingService && step === 2 && (
        <Title color={"#676D72"}>¿En qué municipio vives?</Title>
      )}
      {false && !error && !loadingService && step === 3 && (
        <>
          <TitleText color={"#015DB1"} size={38} marginBottom={15}>
            {municipio} |
            <TitleSpan color={"#676D72"} size={38}>
              {" "}
              Dosis Disponibles
            </TitleSpan>
          </TitleText>
          <BottomText>
            Selecciona si es tu primera o segunda dosis por aplicar
          </BottomText>
          {/* <Title color={"#676D72"} style={{ fontSize: "1em" }}>
            Dosis disponibles en :{" "}
            <span style={{ color: "#014d98" }}>{municipio}</span>
          </Title> */}
        </>
      )}
      {!error && !loadingService && step === 3 && (
        <>
          <Title color={"#00326C"}>
            Encontramos {selectedCampaigns.length} campaña
            {selectedCampaigns.length > 1 ? "s" : ""} para ti
          </Title>
          {selectedCampaigns.length > 1 && (
            <Title color={"#676D72"}>Selecciona solamente una</Title>
          )}
          {false && <BottomText color={"#676D72"}>
            Si es tu{" "}
            <span
              style={{
                color: "#3BAFFE",
                fontStyle: "italic",
                fontWeight: "600",
              }}
            >
              segunda dosis
            </span>{" "}
            verifica que los biológicos{" "}
            <a
              style={{
                color: "#015DB1",
                borderRadius: "100%",
                border: "1px solid #015DB1",
                padding: "0 6px",
                fontSize: "15px",
                cursor: "pointer",
              }}
              data-tip="Pfizer, Astra Zeneca, Sinovac, Moderna, Cansino."
            >
              ?
            </a>{" "}
            disponibles coincidan con el biológico aplicado en tu
            primera dosis.
          </BottomText>}
          <ReactTooltip
            place="right"
            type="light"
            effect="solid"
            className="biologicos"
          />
        </>
      )}

      {FormComponent}
      {!error && !loadingService && step === 1 && (
        <StepContainer>
          <BottomText style={{ textAlign: "left", fontWeight: "300" }}>
            Si no conoces tu CURP{" "}
            <Link
              href="https://www.gob.mx/curp/"
              target="_blank"
              rel="noopner noreferrer"
            >
              consulta aquí
            </Link>
          </BottomText>
        </StepContainer>
      )}
      {error && !loadingService && serviceAvailable && (
        <PrimaryButton
          onClick={() => {
            setError("");
          }}
        >
          Regresar
        </PrimaryButton>
      )}
      <Modal
        handleClose={handleModal}
        show={showModal}
        showNo={step !== 1}
        headerText={modalTitle}
        detailText={modalText}
        openPos="CM_CENTER"
        noCaption="Cerrar"
      />
      <ModalVideo
        handleClose={handleVideo}
        show={showVideo}
        openPos="CM_CENTER"
      />
    </Container>
  );
};

CurpForm.propTypes = {};

export default CurpForm;
