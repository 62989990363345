import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Styles from './AppointmentValidate.styles';
import IconSuccess from '../../icons/success.svg';
import IconOutOfTime from '../../icons/reloj.svg';
import IconAlert from '../../icons/signo-de-exclamacion.svg';
import IconJaliscoSeDefiende from '../../icons/logo_jalisco_se_defiende_dark.svg';
import AppointmentValidateLogos from '../AppointmenValidateLogos';

const Container = styled.div`${Styles.container}`;
const Card = styled.div`${Styles.card}`;
const CardHeading = styled.div`${Styles.card_heading}`;
const CardBody = styled.div`${Styles.card_body}`;
const CardBodyContent = styled.div`${Styles.card_body_content}`;
const CardFooter = styled.div`${Styles.card_footer}`;

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AppointmentValidate = ({appointment, escenary}) => {
    
    let config = {
        cardHeadingText: null,
        iconStatus: null,
        color: null,
        html: null,
        escenary: escenary 
    }

    const htmlFound = ({ campaigns, uuid, curp, nombre, primer_apellido, segundo_apellido, dateFormatted, hoursFormatted, modulo_vacunacion: { nombre_modulo, municipio }, custom_text }) =>
    <React.Fragment>
        <div className="citizen">
            <span className="folio" style={{cursor:'pointer'}} onClick={()=>{window.location.href=`${process.env.REACT_APP_SIGEVA_URL}/citas/uuid/${uuid}`}}>FOLIO { uuid }</span>
            <span className="curp">CURP <span className="curp-value">{ curp }</span></span>
            <span className="name">{ `${nombre} ${primer_apellido} ${segundo_apellido}` }</span>
        </div>
        <div className="appointment">
            <span className="module">Campaña
                <span className="module-name">{ campaigns.descripcion }</span>
            </span>
            <div className="date">
                <span className="date-badge">{ campaigns.biologico }</span>
            </div>
        </div>
    </React.Fragment>;

    const htmlNotFound = 
    <div className="error">
        <span className="error-text">No se encontro esta cita registrada en la plataforma</span>
    </div>;
    

    switch (config.escenary) {
        case 1:
            config.cardHeadingText = 'Comprobante de invitación';
            config.iconStatus = IconSuccess;
            config.color = '#01B160';
            config.html = htmlFound({
                ...appointment,
                custom_text: 'Fecha Vigente'
            });
        break;
        
        case 2:
            config.cardHeadingText = 'Invitación fuera de tiempo';
            config.iconStatus = IconOutOfTime;
            config.color = '#FDC837';
            config.html = htmlFound({
                ...appointment, 
                custom_text: 'Fecha no vigente'
            });
        break;

        case 3:
            config.cardHeadingText = 'Invitación no válida';
            config.color = '#FD3737';
            config.iconStatus = IconAlert;
            config.html = htmlNotFound;
        break;
    
        default: break;
    }

    return (
        <Container>
            <Card>
                <CardHeading color={ config.color }>{ config.cardHeadingText }</CardHeading>
                <CardBody>
                    {/* <img src={ config.iconStatus } alt="Estado de comprobante" className="icon icon-status"/> */}
                    {/* <img src={IconJaliscoSeDefiende} alt="Ante la pandemia Jalisco se defiende" className="icon icon-logo-top"/> */}
                    <CardBodyContent color={ config.color }>
                        { config.html }
                    </CardBodyContent>
                </CardBody>
                <CardFooter>
                    <AppointmentValidateLogos color={'white'} />
                </CardFooter>
            </Card>
        </Container>
    );
};

AppointmentValidate.propTypes = {
};

export default AppointmentValidate;
