import styled from "styled-components";
import PropTypes from "prop-types";
import React from "react";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";

// These are private components

// Modal background layer - visible or invisible, but always floating above client's elements
const Model = styled.div`
  z-index: 35;
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(74, 84, 92, 0.6);
`;

// Rendered popup - a positional demo confirmation box
const Container = styled.div`
z-index: 40;

  position: fixed;
  background: antiquewhite;
  width: 60%;
  min-height: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 2rem;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 1rem;
  @media (max-width: 768px) {
    width: 90%;
    padding: 2rem;
    min-height: 400px;
    font-size: 0.8rem;
  }
`;

const Header = styled.div`
  font-size: 2em;
  color: #014d98;
`;

const HBar = styled.div`
  width: 100%;
  height: 1px;
  border: solid 1px rgba(80, 80, 150, 0.4);
  background-color: rgba(80, 80, 150, 0.4);
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Slot = styled.div`
  font-size: 1.5em;
  color: rgba(103, 109, 114, 1);
  max-height: 70vh;
  overflow: scroll;
  margin-bottom: 1em;
`;

export default function Modal(props) {
  const {
    handleClose, // renderProp fn returns true or false
    show, // boolean - visible/invisible
    headerText, // text
    detailText, // html / inner text
    openPos, // symbol for placement
    noCaption,
    yesCaption,
    showNo,
  } = { ...props };

  const sendYes = () => handleClose(false);
  const sendNo = () => handleClose(true);

  return (
    <Model show={show}>
      <Container openPos={openPos}>
        <Header>{headerText}</Header>
        <Slot>{detailText}</Slot>
        {detailText.includes &&
          (detailText.includes("citas") ||
            detailText.includes("dosis") ||
            detailText.includes("habilitado")) && (
            <Slot>
              Consulta las campañas vigentes aquí{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://gobjal.mx/VacunacionJalisco"
              >
                https://gobjal.mx/VacunacionJalisco
              </a>{" "}
              y sigue la página de{" "}
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/SaludJalisco"
              >
                https://www.facebook.com/SaludJalisco
              </a>{" "}
              para enterarte de nuevas jornadas donde podrás registrarte.
            </Slot>
          )}
        <ButtonBar>
          {showNo && (
            <SecondaryButton onClick={sendNo}>
              {noCaption ? noCaption : "Cancelar"}
            </SecondaryButton>
          )}
          <PrimaryButton
            style={{ marginLeft: "2em" }}
            onClick={sendYes}
            primary={true}
          >
            {yesCaption ? yesCaption : "Regresar al inicio"}
          </PrimaryButton>
        </ButtonBar>
      </Container>
    </Model>
  );
}

Modal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  detailText: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  openPos: PropTypes.symbol.isRequired,
};
