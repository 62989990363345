import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Styles from './MomentoFrente.styles';

import IconFlecha from '../../icons/trazo_flecha_derecha.png';

const Container = styled.div`${Styles.container}`;
const Flecha = styled.img`${Styles.icon}`;
const Texto = styled.div`${Styles.texto}`;
const TextLine = styled.span`
`;
const TextBold = styled.span`
    font-weight: bold;
    color: #676D72;
`;
const TextNormal = styled.span`
`;
const TextEnfasis = styled.span`
    color: #00326C;
    font-size: 1.5em;
    font-weight: bold;
`;
const TextAzulClaro = styled.span`
    color: #37AEFD;
    font-size: .75em;
    font-weight: bold;
`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({color}) => {
    return (
        <Container>
            <Flecha src={IconFlecha} />
            <Texto>
                <TextLine><TextBold>Llegó el momento</TextBold> <TextNormal>de hacerle frente al</TextNormal></TextLine>
                <TextEnfasis>CORONAVIRUS</TextEnfasis>
                <TextAzulClaro>¿Te interesa vacunarte contra el COVID-19?</TextAzulClaro>
            </Texto>
        </Container>
    );
};

SponsorsLogos.propTypes = {
    color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
