import { css } from 'styled-components';

const styles = {
    container: ({color}) => css`
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        width: 100%;
        background-color: ${color}
    `,
    icon: ({maxWidth, marginSides}) => css`
        display:flex;
        flex-direction:column;
        margin-top: 1.5em;
        margin-bottom: 1.5em;
        ${maxWidth && css`
            max-width: ${maxWidth};
        `}
        margin-left: ${marginSides};
        margin-right: ${marginSides};
        @media(max-width:768px){
            max-width:120px;
        }
    `,
};

export default styles;