import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import Home from './views/Home'
import Register from './views/Register'
import Appointment from './views/Appointment'
import Success from './views/Success'
import AppointmentValidation from './views/AppointmentValidation'
import './App.css'
import { initGA } from './utils/analytics'
import Confirm from './views/Confirm'

initGA()

const SITE_KEY = process.env.REACT_APP_RECAPTCHA_KEY

function App () {
  useEffect(() => {
    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id)

      if (!isScriptExist) {
        var script = document.createElement('script')
        script.type = 'text/javascript'
        script.src = url
        script.id = id
        script.onload = function () {
          if (callback) callback()
        }
        document.body.appendChild(script)
      }

      if (isScriptExist && callback) callback()
    }

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,
      function () {
        console.log('Script loaded!')
      }
    )
  }, [])

  document.addEventListener('wheel', function (event) {
    if (document.activeElement.type === 'number') {
      document.activeElement.blur()
    }
  })

  return (
    <div className='App'>
      <Router basename={`${process.env.REACT_APP_BASENAME}`}>
        <Switch>
          <Route path='/confirm'>
            <Confirm />
          </Route>
          <Route path='/register'>
            <Register />
          </Route>
          <Route path='/appointment'>
            <Appointment />
          </Route>
          <Route path='/codigo/:code' children={<AppointmentValidation />} />
          <Route path='/'>
            <Home />
          </Route>
        </Switch>
      </Router>
    </div>
  )
}

export default App
