import React from 'react'
import MomentoFrente from '../layout/MomentoFrente/'
import SponsorsLogos from '../layout/SponsorsLogos/'
import CuidarseLogos from '../layout/CuidarseLogos/'
import CurpHome from '../layout/CurpHome'
import HomeHeader from '../layout/HomeHeader'
import AuthHeader from '../layout/AuthHeader'
import Footer from '../layout/Footer'
import MenuHeader from '../layout/MenuHeader'
function Home () {
  window.localStorage.clear();
  return (
    <div className='App-header'>
      <MenuHeader />
      {/* <MomentoFrente /> */}
      <CurpHome />
      {/* <CuidarseLogos color='#FFFFFF' /> */}
      <SponsorsLogos color='#FAFAFA' marginSides='2em' />
      {/* <Footer color={'#4B5056'} /> */}
    </div>
  )
}

export default Home
