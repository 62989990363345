import { css } from 'styled-components';

const styles = {
    container: () => css`
        width:100%;
        height: 5em;
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        justify-content: space-between;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-bottom: 1px #C6CACE solid;
        opacity: 1;
        @media(max-width: 700px){
            height: 10em;
            width: 100%;
            align-items:center;
            flex-direction:column-reverse;
            justify-content:space-around;
        }
    `,
    icon: () => css`
        width: 1.25em;
        height: 1.25em;
        margin-right: 1em;
        .a{fill:#37aefd;stroke:#37aefd;}
    `
};

export default styles;