import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Styles from "./ActiveCampaigns.style";
import Carousel, { consts } from "react-elastic-carousel";
import ico_left from "../../icons/ico_left.svg";
import ico_right from "../../icons/ico_right.svg";
import protector from "../../assets/images/5a7.svg";
import cinco from "../../assets/images/5mas.png";
import doce from "../../assets/images/12mas.png";
import quince from "../../assets/images/15mas.png";
import dieciocho from "../../assets/images/18mas.png";
import treinta from "../../assets/images/30mas.png";
import cuarenta from "../../assets/images/40mas.png";
import cincuenta from "../../assets/images/50mas.png";
import sesenta from "../../assets/images/60mas.png";
import embarazada from "../../assets/images/embarazada.png";
import influenza from "../../assets/images/ico_influenza.png";
import docentes from "../../assets/images/ico_docentes.png";
import salud from "../../assets/images/ico_salud.png";
import none from "../../icons/ico_none.svg";
import informacion from "../../assets/images/informacion.svg";
import calendario from "../../assets/images/calendar.svg";
import institucionalizados from "../../assets/images/institucionalizados.png";
import cancer from "../../assets/images/personas-cancer.png";
import embarazadas_vih from "../../assets/images/embarazadas_vih.png";
import comorbilidades from "../../assets/images/personas-comorbilidades.png";
import comorbilidadData from "../../data/comorbilidades";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

const ActiveCampaigns = ({ step, setShowModal, setModalText, setModalTitle }) => {
  const Button = styled.button`
    ${Styles.button}
  `;
  const Dosis = styled.span`
    ${Styles.dosis}
  `;

  const Item = styled.div`
    ${Styles.card}
  `;

  const Img = styled.img``;

  const IconContainer = styled.div`
    ${Styles.iconContainer}
  `;
  const Icon = styled.img`
    ${Styles.icon}
  `;
  const Divider = styled.div`
    ${Styles.divider}
  `;
  const Title = styled.h1`
    ${Styles.header}
  `;
  const SubTitle = styled.b`
    ${Styles.subtitle}
  `;
  const Text = styled.p`
    ${Styles.age}
  `;

  const TextCard = styled.div`
    ${Styles.age}
  `;

  const Card = styled.div`
    ${Styles.messageCard}
  `;
  const { height, width } = useWindowDimensions();

  const [campaigns, setCampaigns] = useState([]);

  const [images, setimages] = useState({
    0: influenza,
    5: cinco,
    12: doce,
    15: quince,
    18: dieciocho,
    30: treinta,
    40: cuarenta,
    50: cincuenta,
    53: institucionalizados,
    54: cancer,
    55: embarazadas_vih,
    56: comorbilidades,
    60: sesenta,
  });

  const showInformation = (campaign) => {
    if (!comorbilidadData[campaign]) return;
    const mText = <ul>
      {
        comorbilidadData[campaign].comorbilidades.map((comorbilidad, index) => {return <li style={{
          textAlign: "left",
          lineHeight: "1.15em"
        }} key={`comorbilidad-${campaign}-${index}`}>- {comorbilidad}</li>})
      }
    </ul>
    setModalTitle(comorbilidadData[campaign].title);
    setModalText(mText);
    setShowModal(true);
  }

  const options = { month: "long", day: "numeric" };

  useEffect(() => {
    const campañas = fetch(
      `${process.env.REACT_APP_API_URL}/registro/getActiveCampaigns`
    )
      .then((res) => {
        if (!res.ok) {
          throw res;
        }
        return res.json();
      })
      .then((data) => {
        setCampaigns(data);
      });
  }, []);

  const Arrow = ({ type, onClick, isEdge }) => {
    const pointer =
      type === consts.PREV ? <Img src={ico_right} /> : <Img src={ico_left} />;
    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };

  return (
    <>
      <Title color={"#015DB1"}>
        Campaña 2024 para grupos vulnerables
      </Title>
      {step && step === 1 && campaigns?.length > 0 && (
        <div style={{ width: "100%", marginTop: "25px" }}>
          <Carousel
            renderArrow={Arrow}
            style={{ width: "100%", height: "fit-content" }}
            pagination={false}
            itemsToScroll={width <= 780 ? 1 : 2}
            itemsToShow={width <= 780 ? 1 : 2}
          >
            {campaigns.map((campaign, i) => {
              let dosisText = "Primera Dosis";
              if(campaign.id === 17){
                dosisText = 'Dosis Única';
              }else if(campaign.num_dosis === 2){
                dosisText = 'Segunda Dosis';
              }else if(campaign.num_dosis === 3){
                dosisText = 'Dosis de Refuerzo';
              }else if(campaign.num_dosis === 4){
                dosisText = 'Segundo Refuerzo';
              }


              return (
                <Item key={i}>
                  <IconContainer>
                    <Icon
                      src={
                        campaign.embarazada
                          ? embarazada
                          : [22,43].includes(campaign.id) ? docentes
                          : campaign.id == 23 ? salud
                          : [53,54,55,56].includes(campaign.id) ? images[campaign.id]
                          : images[campaign.edad_minima]
                      }
                    />
                  </IconContainer>
                  <SubTitle size={18}>
                    {campaign.descripcion
                      ?.replace(/\([^()]*\)/g, "")
                      .replace(/'/g, "")
                      .replace(/\(|\)/g, "")}
                  </SubTitle>
                  <Text
                    size={18}
                    color="#37AEFE"
                    styleFont="italic"
                    weight={600}
                  >
                  </Text>
                  {
                    campaign?.id === 17 && 
                    (
                      <>
                        <Text
                          size={16}
                          color="#FF6C00"
                          weight={600}
                        >
                          - Embarazadas
                        </Text>
                        <Text
                          size={16}
                          color="#FF6C00"
                          weight={600}
                        >
                          - Niños de 6 meses a 5 años
                        </Text>
                        <Text
                          size={16}
                          color="#FF6C00"
                          weight={600}
                        >
                          - Personas mayores de 60 años
                        </Text>
                        <Text
                          size={16}
                          color="#FF6C00"
                          weight={600}
                        >
                          - Pacientes con enfermedades crónicas
                        </Text>
                      </>
                    )
                  }
                  {
                      [53,54,55,56].includes(campaign.id) &&
                      <Text
                        size={15}
                        color="#FF6C00"
                        styleFont="italic"
                        weight={600}
                        style={{marginTop:'1em', marginBottom:'1em', cursor:'pointer'}}
                        onClick={()=>{console.log("CLICKED"); showInformation(campaign.id)}}
                      >
                        <Icon src={informacion} style={{width:'16px', height:'16px'}}/>
                        ¿Quiénes se incluyen?
                      </Text>

                  }

                  {campaign?.biologico && (
                      <Text
                        size={15}
                        color="#37AEFE"
                        weight={600}
                        style={{ textTransform: "uppercase" }}
                      >
                        <Icon src={calendario} style={{width:'16px', height:'16px'}}/>
                        {campaign.biologico}
                      </Text>
                  )}
                  {campaign?.num_dosis >= 2 && (
                      <Text
                        size={15}
                        color="#FF6C00"
                        styleFont="italic"
                        weight={600}
                      >
                        {campaign.num_dosis === 2 ? 'Primera' : 'Última'} dosis aplicada antes del{" "}
                        {new Date(
                          campaign.max_prev_dose_date + "T00:00:00-06:00"
                        ).toLocaleDateString("es-MX", options)}
                      </Text>
                  )}
                </Item>
              );
            })}
          </Carousel>
        </div>
      )}
      {step && step === 1 && campaigns?.length == 0 && (
        <Card>
          <div style={{textAlign:"center"}}>
            <Icon src={none} style={{marginRight:"0", height:"auto"}}></Icon>
            <TextCard
              size={20}
              color="#676d72"
              weight={800}
              style={{ marginTop: "15px", width: "100%", display: "block" }}
            >
              En este momento no se encuentran campañas de vacunación vigentes.
            </TextCard>
            <TextCard
              size={20}
              color="#676d72"
              weight={400}
              style={{ marginTop: "15px", width: "100%", display: "block" }}
            >
              Mantente atento de nuestros canales oficiales donde informamos
              oportunamente de la apertura de nuevos registros.
            </TextCard>
          </div>
        </Card>
      )}

      <Divider />
    </>
  );
};

ActiveCampaigns.propTypes = {};

export default ActiveCampaigns;
