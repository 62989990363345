const validate = form => {
  let required = { ...form }
  delete required.numInt
  delete required.segundoApellido
  delete required.renapo
  delete required.edad
  delete required.telefono2
  delete required.fecha_aplicacion
  delete required.segundo_apellido_tutor
  let phoneValidation = validatePhones(form)
  if (phoneValidation !== true) return phoneValidation

  if(form.cp.length !== 5){
    return `El campo ${messages.cp} no tiene un formato válido. Asegúrate de ingresar 5 dígitos.`;
  }

  if (!isValidEmail(form.email))
    return `El campo ${messages.email} no tiene un formato válido. Asegurate de ingresar un email válido.`

  let requiredValidation = validateRequired(required)
  if (requiredValidation !== true) return requiredValidation

  return true
}

const validateRequired = required => {
  for (let attr in required) {
    if (!required[attr]) return `El campo ${messages[attr]} es requerido.`
  }
  return true
}

const validatePhones = form => {
  if (!isValidPhone(form.telefono1))
    return `El campo ${messages.telefono1} no tiene un formato válido. Asegurate de ingresar 10 dígitos.`

  if (form.telefono2.length > 0 && !isValidPhone(form.telefono2))
    return `El campo ${messages.telefono2} no tiene un formato válido. Asegurate de ingresar 10 dígitos.`

  return true
}

const isValidPhone = phone => {
  return /^[0-9]{10}$/.test(phone)
}

const isValidEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const messages = {
  curp: 'CURP',
  nombre: 'Nombre',
  primerApellido: 'Primer apellido',
  segundoApellido: 'Segundo apellido',
  fechaNacimiento: 'Fecha de nacimiento',
  edad: 'Edad',
  sexo: 'Sexo',
  entidadDeNac: 'Entidad de nacimiento',
  entidadFederativa: 'Entidad federativa',

  telefono1: 'Teléfono de contacto 1',
  telefono2: 'Teléfono de contacto 2',
  email: 'Correo electrónico',
  calle: 'Calle',
  numExt: 'Numero exterior',
  numInt: 'Numero interior',
  cp: 'Código Postal',
  municipio: 'Municipio',
  colonia: 'Colonia',
  diabetes: 'Diabetes',
  hipertension: 'Hipertensión',
  otra: 'Padecimiento',
  num_dosis: 'Vacuna',

  nombre_tutor: 'Nombres del tutor',
  primer_apellido_tutor: 'Primer apellido del tutor',
  folio_mivacuna: 'Folio mi vacuna'
}

const ValidationHelper = {
  validate
}

export default ValidationHelper

export { validate }
