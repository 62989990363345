import "./header.css";
import imgJaliscoSeDefiende from "../../icons/logo_jaliscosedefiende_small.svg";
import imgJaliscoGob from "../../icons/logo_jal_small.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const mobileMenu = () => {
  const hamburger = document.querySelector(".hamburger");
  const navMenu = document.querySelector(".nav-menu");
  hamburger.classList.toggle("active");
  navMenu.classList.toggle("active");
};

const closeMenu = () => {
  const hamburger = document.querySelector(".hamburger");
  const navMenu = document.querySelector(".nav-menu");
  hamburger.classList.remove("active");
  navMenu.classList.remove("active");
};

const MenuHeader = () => {
  return (
    <header className='shadow-md relative' style={{width:"100%", zIndex:"20", height:"75px"}}>
      <nav className='container containerFluid'>
        <div className='flex justify-center lg:justify-between items-center py-2'>
          <div className='flex flex-row items-center'>
            <img
              src={imgJaliscoSeDefiende}
              alt='Ante la pandemia Jalisco se defiende'
              className='jalisco-gob pr-4 lg:pr-12 border-r'
            />
            <img src={imgJaliscoGob} alt='Jalisco, Gobierno del estado' className='jalisco-se-defiende pl-4 lg:pl-12' />
          </div>
          <ul className='nav-menu z-50'>
            <li className='nav-item'>
              <a href='https://calculatudosis.vacunacion.jalisco.gob.mx/' title='Calcula tu próxima dosis' className='nav-link' style={{textDecoration:"none"}} onClick={closeMenu}>
                Calcula tu próxima dosis
              </a>
            </li>
            <li className='nav-item'>
              <a
                href='https://transparenciasitgej.jalisco.gob.mx/api/api/banco_archivos/131542/downloadWeb'
                title='Aviso de privacidad'
                target='_blank'
                className='nav-link'
                onClick={closeMenu}
                rel='noreferrer'
                style={{textDecoration:"none"}}
              >
                <FontAwesomeIcon icon={faInfoCircle} /> Aviso de privacidad
              </a>
            </li>
          </ul>
          <div className='hamburger ml-auto lg:ml-0 mr-2' onClick={mobileMenu}>
            <span className='bar'></span>
            <span className='bar'></span>
            <span className='bar'></span>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default MenuHeader;
