import React, { useEffect, useState } from 'react';
import { useParams } from "react-router";
import AppointmentValidate from '../layout/AppointmentValidate';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const LoadingDiv = styled.div`
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100vh;
        background-color: linen;
        font-size: 1.5em;
    `;

const AppointmentValidation = () => {
    const { code } = useParams();
    const [appointment, setAppointment] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(async () => {
      try{
        const result = await appointmentData();
        result['dateFormatted'] = dateFormatted(result?.fecha_cita);
        result['hoursFormatted'] = hoursFormatted(result?.fecha_cita);
        console.log(result);
        setAppointment(result);
        setLoading(false);
      }catch(ex){
          //
      }
    }, []);

    const dateFormatted = (d) => {
      try{
        let frmt = new Date(d);
        return new Intl.DateTimeFormat("es-MX", {
          month: "long",
          weekday: 'long',
          day: "2-digit",
          timeZone: 'America/Mexico_City'
        }).format(frmt);
      
      } catch (e){
        return d;
      }
    }
    
    const hoursFormatted = (d) => {
      try{
        let frmt = new Date(d);
        return new Intl.DateTimeFormat("es-MX", {
          hour: "numeric",
          minute: 'numeric',
          timeZone: 'America/Mexico_City'
        }).format(frmt)+(frmt.getHours() >= 12?' pm':' am');
      
      } catch (e){
        return d;
      }
    }

    const appointmentData = () => fetch(`${process.env.REACT_APP_API_URL}/registro/${code}`).then((resp) => (resp.json()));

    const getEscenary = () => {
        return appointment.campaigns.id >= 53 && appointment.campaigns.id <= 56 ? 1 : 3;
      return typeof appointment?.isToday !== 'undefined'  ? (appointment.isToday ? 1 : 2) : 3;
    }


    return (
       loading?(<LoadingDiv><FontAwesomeIcon icon={faCircleNotch} spin color="gray" />&nbsp;Buscando ...</LoadingDiv>):(<AppointmentValidate appointment={appointment} escenary={getEscenary()} />) 
    );
}

export default AppointmentValidation;
