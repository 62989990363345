import React from "react";
import PropTypes from "prop-types";

import AvisoPDF from "../../assets/pdf/AVISO_PRIVACIDAD_EPIDEMIOLOGIA_2021.pdf";

import LogoJaliscoDefiende from "../../icons/logo_jaliscosedefiende_small.svg";
import LogoGobiernoDelEstado from "../../icons/logo_jal_small.svg";
import styled from "styled-components";
import InfoIcon from "../../icons/ico_avisoprivacidad.svg";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 80px;
  z-index: 500;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 21px #00000010;
  opacity: 1;
  @media (max-width: 780px) {
    width: 100%;
    height: 68px;
  }
`;
const Icon = styled.img`
  width: 180px;
  height: auto;
  @media (max-width: 780px) {
    width: 120px;
  }
`;
const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50%;
  @media (max-width: 780px) {
    padding: 0;
    width: 65%;
    justify-content: flex-start;

  }
`;

const Divider = styled.div`
  height: 50px;
  align-self: center;
  width: 2px;
  background-color: #c9cacb;
  margin: 5px 40px;
  opacity: 1;
  @media (max-width: 780px) {
    display: none;
  }
`;

const MenuContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 1024px) {
    padding: 0;
    width: 35%;
  }
`;
const AvisoIcon = styled.img`
  margin-right: 5px;
`;
const MenuItem = styled.a`
  display: flex;
  text-align: left;
  font: normal normal bold 16px/26px Nutmeg;
  letter-spacing: 0px;
  color: #37aefd;
  opacity: 1;
  @media (max-width: 1024px) {
    display: none;
  }
`;

const logos = [
  {
    src: LogoJaliscoDefiende,
    alt: "Logo Jalisco Se Defiende",
    maxWidth: "180px",
  },
  {
    src: LogoGobiernoDelEstado,
    alt: "Logo Gobierno del Estado de Jalisco",
    maxWidth: "180px",
  },
];

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({ color }) => {
  return (
    <Container>
      <IconContainer>
        {logos.map((icon, idx) => {
          if (idx == 0) {
            return (
              <>
                <Icon
                  key={`iconrow-${idx}`}
                  src={icon.src}
                  alt={icon.alt}
                  style={{ marginRight: "10px" }}
                />
                <Divider></Divider>
              </>
            );
          } else {
            return (
              <Icon key={`iconrow-${idx}`} src={icon.src} alt={icon.alt} />
            );
          }
        })}
      </IconContainer>
      <MenuContainer>
        <MenuItem href="#" style={{marginRight: "40px", textDecoration: "none"}}>Calcula tu segunda dosis</MenuItem>
        <MenuItem href={AvisoPDF} style={{textDecoration: "none"}} target="_blank">
          <AvisoIcon src={InfoIcon} />
          Aviso de Privacidad
        </MenuItem>
      </MenuContainer>
    </Container>
  );
};

SponsorsLogos.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
