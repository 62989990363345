import { css } from 'styled-components'

const styles = {
  container: () => css`
    width: 100%;
    height: 90px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    opacity: 1;
    position: fixed;
    z-index: 100;
    top: 0;
    @media (max-width: 700px) {
      height: 120px;
      position: absolute;
    }
  `,
  subcontainer: () => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    @media (max-width: 700px) {
      flex-direction: row;
    }
  `,
  icon: () => css`
    height: 50px;
    margin-left: 1.5em;
    margin-right: 1.5em;
  `,
  iconsmall: () => css`
    height: 30px;
    margin-left: 1.5em;
    margin-right: 1.5em;
  `
}

export default styles
