import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Styles from "./CurpHome.styles";

import CurpForm from "../CurpForm";
import JaliscoHacemos from "../JaliscoHacemos";
import AvisoLink from "../../components/AvisoLink";
import IcoTel from "../../icons/ico_tel.svg";

const Container = styled.div`
  ${Styles.container}
`;
const Hacemos = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  z-index: 0;
`;
const Whatsapp = styled.a`
  ${Styles.whatsapp}
`;

const WhatsappShort = styled.a`
  ${Styles.whatsappShort}
`;

const TelIcon = styled.img`
  ${Styles.tel}
`;

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const SponsorsLogos = ({ color }) => {
  return (
    <Container className="algo">
      <CurpForm />
      <div
        style={{
          display: "flex",
          width: "80%",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: "10",
          marginTop: "20px",
        }}
      >
        {/* <AvisoLink color={'#ffffff'}/>
                <span style={{fontWeight:'bold'}}>Dudas al teléfono <br/><a href="tel:3338233220" rel="noopener noreferrer" target="_blank" style={{color:'#ffffff'}}>33 3823 3220</a></span> */}
      </div>
      <Hacemos>
        <JaliscoHacemos />
      </Hacemos>
      <Whatsapp href={`tel:+523338233220`}>
        <TelIcon src={IcoTel} />
        33 3823 3220
      </Whatsapp>
      <WhatsappShort href={`tel:+523338233220`}>
        <TelIcon src={IcoTel} />
      </WhatsappShort>
    </Container>
  );
};

SponsorsLogos.propTypes = {
  color: PropTypes.string.isRequired,
};

export default SponsorsLogos;
