const comorbilidades = {
  53: {
    comorbilidades: [
      "Niñas, niños, adolescentes y adultos mayores en albergues (asilos, casas hogar) o institucionalizados."
    ],
    title: 'Niñas, niños, adolescentes y adultos mayores institucionalizados',
    options: [
      "Adultos mayores en asilos y casa de asistencia",
      "Niñas, niños y adolescentes en casa hogar",
    ]
  },
  54: {
    comorbilidades: [
      "Niñas, niños, adolescentes con cáncer en cualquier etapa.",
      "Adultos con cáncer activo o remiso.",
      "Enfermedad renal crónica.",
      "Trasplantados (hígado, riñón o médula ósea)"
    ],
    title: 'Cáncer, enfermedad renal y transplantados',
    options: [
      "Cáncer en la infancia y adolescencia en cualquier etapa",
      "Cáncer activo o remiso en adultos",
      "Post trasplantados (riñón, hígado, médula ósea)",
      "Enfermedad renal crónica",
    ]
  },
  55: {
    comorbilidades: [
      "Embarazadas.",
      "Población indígena con enfermedad inmunosupresora.",
      "Personas con VIH."
    ],
    title: 'Embarazadas y VIH',
    options: [
      "Personas con VIH cualquier edad",
      "Personas embarazadas",
      "Población indígena con enfermedad inmunosupresora",
    ]
  },
  56: {
    comorbilidades: [
      "Personas con enfermedad neuromuscular grave",
      "Fibrosis quística",
      "Malformación congénita de riñón, hígado o sistema digestivo",
      "Distrofia muscular (neurodiscapacidad)",
      "Parálisis cerebral",
      "Esclerosis múltiple",
      "Asplenia",
      "Diabetes mellitus no controlada",
      "Enfermedad cardiovascular",
      "Enfermedad autoinmune",
      "Adulto mayor con enfermedad crónica no transmisible no controlada",
      "Adultos Mayores de 65 años",
      "Cualquier edad con enfermedad pulmonar cronica (como Asma, Epoc, enfisema, etc)",
      "Cualquier edad con antecedentes de COVID grave (fibrosis residual)",
      "Cualquier edad con enfermedad reumatológica en descontrol",
      "Cualquier edad Personas con Diabetes tipo 1"
    ],
    title: 'Otras comorbilidades',
    options: [
      "Adultos mayores en postración",
      "Enfermedad neuromuscular grave (neurodiscapacidad)",
      "Fibrosis quística",
      "Malformación congénita de riñón",
      "Malformación congénita de hígado",
      "Malformación congénita de sistema digestivo",
      "Parálisis cerebral",
      "Distrofia muscular (neurodiscapacidad)",
      "Esclerosis múltiple",
      "Asplenia",
      "Diabetes mellitus no controlada",
      "Enfermedad cardiovascular",
      "Enfermedad autoinmune",
      "Adulto mayor con enfermedad crónica no transmisible no controlada",
      "Adultos Mayores de 65 años",
      "Cualquier edad con enfermedad pulmonar cronica (como Asma, Epoc, enfisema, etc)",
      "Cualquier edad con antecedentes de COVID grave (fibrosis residual)",
      "Cualquier edad con enfermedad reumatológica en descontrol",
      "Cualquier edad Personas con Diabetes tipo 1"
    ]
  },
}
export default comorbilidades;
