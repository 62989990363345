import { css } from 'styled-components'

const styles = {
  container: () => css`
    width: 100%;
    height: 3em;
    display: flex;
    flex-direction: column;
    background: #ffffff 0% 0% no-repeat padding-box;
    color: #bac0c3;
    margin-right: 1.5em;
    margin-top: 1.5em;
    opacity: 1;
  `,
  caption: () => css`
    display: flex;
    height: 1.25em;
    font-size: 0.75em;
    margin-bottom: 4px;
    color: rgb(136 145 150);
  `,
  textbox: () => css`
    border-radius: 4px;
    color: #a6a6af;
    padding-left: 1.5em;
    height: 2em;
    border: 1px solid rgb(136, 145, 150);
    :disabled {
      background-color: #eaedef;
      border: none;
      height: 5em;
    }
    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  `,
  select: () => css`
    border-radius: 4px;
    color: #a6a6af;
    background-color: #ffffff;
    padding-left: 1.5em;
    height: 3em;
    border: 1px solid rgb(136, 145, 150);
    padding-top: 1px;
    padding-bottom: 1px;
    box-sizing: border-box;
    :disabled {
      background-color: #eaedef;
      border: none;
    }
  `,
  option: () => css``,
  radioGroup: () => css`
    width: 100%;
    margin-top: 1.5em;
    display: flex;
    flex-direction: row;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-right: 1.5em;
    opacity: 1;
  `,
  radio: () => css`
    margin-right: 0.5em;
    color: #676d72;
    :selected {
      color: #4597ea;
    }
  `,
  radioLabel: ({ selected }) => css`
    color: ${selected ? '#4597EA' : '#676D72'};
    margin-right: 1.5em;
  `,
  label: () => css`
    display: flex;
    height: 1.25em;
    font-size: 0.8em;
    align-items: center;
  `,
  jaliscoCaption: () => css`
    display: flex;
    height: 1.25em;
    font-size: 1.1em;
    margin-bottom: 0.15em;
    font-weight: bold;
    color: #3b4a58;
  `,
  jaliscoRadioGroup: () => css`
    width: 100%;
    // margin-top: 1em;
    display: flex;
    flex-direction: row;
    background: #ffffff 0% 0% no-repeat padding-box;
    margin-right: 1.5em;
    opacity: 1;
  `,
  jaliscoRadioLabel: ({ selected }) => css`
    color: ${selected ? '#4597EA' : '#676D72'};
    padding:0.5em;
  `
}

export default styles
