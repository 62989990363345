import React, { useEffect, useState } from 'react'
import AuthHeader from '../layout/AuthHeader'
import AuthUser from '../layout/AuthUser'
import SponsorsLogos from '../layout/SponsorsLogos'
import RegisterForm from '../layout/RegisterForm'
import Footer from '../layout/Footer'
function Register () {
  const [user, setUser] = useState({})
  useEffect(() => {
    const userData = window.localStorage.getItem('user')
    const campaign = window.localStorage.getItem('campaign');
    if (!userData) {
      alert('Debe ingresar su CURP primero')
      window.location.href = `${process.env.REACT_APP_URL}`
    }
    try {
      const localUser = JSON.parse(userData)
      const localCampaign = JSON.parse(campaign);
      localUser.campaign = localCampaign;
      setUser(localUser)
    } catch (ex) {
      window.localStorage.removeItem('user')
      window.location.href = `${process.env.REACT_APP_URL}`
    }
  }, [])
  let width = '80%'
  if (window.innerWidth <= 700) width = '100%'
  return (
    (user.curp && (
      <div className='App-header' style={{ minHeight: '100vh' }}>
        <AuthHeader />
        <div
          style={{
            width: '100%',
            position: 'absolute',
            top: '120px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            boxSizing:'border-box'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              flexDirection: 'column',
              width
            }}
          >
            <AuthUser CURP={user.curp} municipio={user.municipio ? user.municipio : user.selectedMunicipio} dosis={user.num_dosis} nombre={user.campaign.descripcion} embarazada={user.campaign.embarazada} edad={user.campaign.edad_minima} campaignId={user.campaign.id} />
            <RegisterForm {...user} />
          </div>
          <SponsorsLogos color={'white'} />
          <Footer color={'#00326C'} />
        </div>
      </div>
    )) || <></>
  )
}

export default Register
