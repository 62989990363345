import React from 'react';
import styled from 'styled-components';
import Styles from './CampaignData.styles';

import {ReactComponent as InfoIcon} from '../../icons/icon_info.svg';

import icon60 from '../../assets/images/60mas@2x.png'
import icon50 from '../../assets/images/50mas@2x.png'
import icon40 from '../../assets/images/40mas@2x.png'
import icon30 from '../../assets/images/30mas@2x.png'
import icon18 from '../../assets/images/18mas@2x.png'
import icon15 from '../../assets/images/15mas@2x.png'
import icon12 from '../../assets/images/12mas@2x.png'
import icon5 from '../../assets/images/5mas@2x.png';
import icon0 from '../../assets/images/ico_influenza@2x.png';
import iconEmbarazada from '../../assets/images/embarazada@2x.png';
import iconDocentes from '../../assets/images/ico_docentes@2x.png';
import iconSalud from '../../assets/images/ico_salud@2x.png';
import informacion from "../../assets/images/informacion.svg";
import calendario from "../../assets/images/calendar.svg";
import institucionalizados from "../../assets/images/institucionalizados@2x.png";
import cancer from "../../assets/images/personas-cancer@2x.png";
import embarazadas_vih from "../../assets/images/embarazadas_vih@2x.png";
import comorbilidades from "../../assets/images/personas-comorbilidades@2x.png";

const Container = styled.div`${Styles.container}`;

const IconContainer = styled.div`
    display:flex;
    flex-direction: row;
`;
const CampaignIcon = styled.img`
    display:flex;
    height: 4em;
    margin-right:1em;
`;
const TextLine = styled.span``;
const TextContainer = styled.div`
    color: #989FA5;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`;
const TextNormal = styled.span`
    color: #989FA5;
`;
const TextMunicipio = styled.span`
    color: #676D72;
`;
const TextDosis = styled.span`
    color: #37AEFE;
`;
const TextCampaign = styled.span`
    color: #00326C;
    font-size: 1em;
    font-weight: bold;
`;


const icons = {
    0: icon0,
    5: icon5,
    18: icon18,
    15: icon15,
    12: icon12,
    30: icon30,
    40: icon40,
    50: icon50,
    53: institucionalizados,
    54: cancer,
    55: embarazadas_vih,
    56: comorbilidades,
    60: icon60
}

/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const CampaignData = ({edad, column, embarazada, municipio, dosis, nombre, curp, id }) => {
    let icon = iconEmbarazada;
    if(!embarazada){
        icon = icons[edad];
        if(!icon) icon=icon50;
    }
    if([22,43].includes(id)){
        icon = iconDocentes;
    }
    if([53,54,55,56].includes(id)){
        icon = icons[id];
    }
    if(id === 23){
        icon = iconSalud;
    }
    let dosisText = 'Primera dosis';
    if(id === 17){
        dosisText = 'Dosis única';
    }else if(dosis === 2){
        dosisText = 'Segunda dosis';
    }else if(dosis === 3){
        dosisText = 'Refuerzo';
    }else if(dosis === 4){
        dosisText = 'Segundo Refuerzo';
    }
    return (
        <Container style={{flexDirection: column ? 'column': 'row'}}>
            <IconContainer><CampaignIcon src={icon} /></IconContainer>
            <TextContainer style={{alignItems: column ? 'center' : 'flex-start'}}>
                {!column && <TextLine><TextNormal>Campaña:</TextNormal></TextLine>}
                {false && <TextLine><TextMunicipio>{municipio}</TextMunicipio> / <TextDosis>{dosisText}</TextDosis></TextLine>}
                <TextLine><TextCampaign>{nombre}</TextCampaign></TextLine>
            </TextContainer>
        </Container>
    );
};

CampaignData.propTypes = {
};

export default CampaignData;
