import styled from "styled-components";
import IcoClose from "../../icons/ico_close_modal.svg";

import "./ModalVideo.css";

// These are private components

// Modal background layer - visible or invisible, but always floating above client's elements
const Model = styled.div`
  z-index: 50;
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(74, 84, 92, 0.6);
`;

// Rendered popup - a positional demo confirmation box
const ContainerFolio = styled.div`
  position: fixed;
  background: antiquewhite;
  width: 80%;
  height: 90% !important;
  @media (max-width: 767px) {
    height: 50% !important;
  }
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  padding: 25px 5%;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 1rem;
  @media (max-width: 768px) {
    width: 90%;
    padding: 2rem;
    min-height: 300px;
    font-size: 0.8rem;
    margin-top: 30px;
  }
`;

const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Close = styled.span`
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
`;

const ModalVideo = (props) => {
  const { handleClose, show, openPos } = { ...props };
  const videoUrl = process.env.REACT_APP_VIDEO_URL ? process.env.REACT_APP_VIDEO_URL : "https://www.youtube.com/embed/wGFPFgBJvDI";
  return (
    <Model show={show}>
      <ContainerFolio openPos={openPos}>
        <Close onClick={() => handleClose(false)}>
          <img src={IcoClose} alt="Cerrar" />
        </Close>
        <ContainerInput>
          <iframe
            id="videoDemo"
            src={videoUrl}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </ContainerInput>
      </ContainerFolio>
    </Model>
  );
};

ModalVideo.propTypes = {};

export default ModalVideo;
