import { css } from "styled-components";

const styles = {
  header: ({ color }) => css`
    font: normal normal normal 1.5em Nutmeg;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
    color: ${color};
    font-size: 36px;
    @media (max-width: 780px) {
      font-size: 26px;
      margin-top: 20px;
    }
  `,
  divider: () => css`
    width: 100%;
    border: 1px solid #efefef;
    margin-top: 20px;
    margin-bottom: 20px;
  `,

  card: () => css`
    align-items: center;
    text-align: left;
    width: 70%;
    height: 190px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 8px #0000001a;
    border-radius: 25px;
    opacity: 1;
    padding: 0 1em 1em 1em;
    margin-bottom: 5px;
    @media (max-width: 767px) {
      width: 100%;
      height: 250px;
    }
  `,
  icon: () => css`
    width: auto;
    height: 40px;
    margin-right: 1em;
    .a {
      fill: #37aefd;
      stroke: #37aefd;
    }
  `,
  button: () => css`
    background-color: transparent;
    height: 50px;
    width: 50px;
    border-style: none;
    cursor: pointer;
    align-self: center;
  `,
  iconContainer: () => css``,
  age: ({
    color = "#00326C",
    size = 12,
    styleFont = "normal",
    weight = 400,
  }) => css`
    color: ${color};
    font-size: ${size}px;
    margin: 0;
    display: flex;
    align-items: center;
    font-style: ${styleFont};
    font-weight: ${weight};
  `,
  subtitle: ({ color = "#00326C", size = 12 }) => css`
    color: ${color};
    font-size: ${size}px;
  `,
  dosis: ({ size = 12 }) => css`
    background-color: #37aefe;
    border-radius: 100%;
    color: #fdfdfd;
    padding: 1px 7px;
    font-size: 12px;
    margin-right: 5px;
    font-style: normal !important;
  `,
  messageCard: () => css`
    background: #e5e5e5;
    margin-top: 20px;
    text-align: center;
    padding: 25px 10%;
    width: 75%;
    border-radius: 15px;
  `,
};

export default styles;
