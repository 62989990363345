import { css } from 'styled-components';

const styles = {
    container: () => css`
        padding-left: 1em;
        display: flex;
        flex-direction: row;
        align-items: center;
    `,
    icon: () => css`
        width: 1.25em;
        height: 1.25em;
        margin-right: 1em;
        .a{fill:#37aefd;stroke:#37aefd;}
    `
};

export default styles;