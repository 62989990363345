import React from 'react';
import styled from 'styled-components';
import Styles from './AuthHeader.styles';

import AvisoLink from '../../components/AvisoLink';
import RadarBlue from '../../icons/logo_radar_blue.png';

import LogoJaliscoDefiende from '../../icons/logo_jalisco_se_defiende_oscuro.png';
import LogoGobiernoDelEstado from '../../icons/logo_gobierno_jalisco_horizontal.png';

const Container = styled.div`${Styles.container}`;
const SubContainer = styled.div`${Styles.subcontainer}`;
const SubContainerDesk = styled.div`${Styles.subcontainer}
    @media(max-width:768px){
        display: none;
    }
`;
const Icon = styled.img`${Styles.icon}`;
const IconSmall = styled.img`${Styles.iconsmall}`;
/**
 * Steps component
 * @property {Object} props - component props
 * @returns {JSX}
 */
const AuthHeader = () => {
    return (
        <Container>
            <SubContainer>
                <Icon src={LogoJaliscoDefiende} alt="Logo Jalisco Se Defiende"  />
                <Icon src={LogoGobiernoDelEstado} alt="Logo Gobierno del Estado de Jalisco" />
            </SubContainer>
            <SubContainerDesk>
                <AvisoLink color={"#4B5056"}/>
                <IconSmall src={RadarBlue} alt="Logo radar azul" />
            </SubContainerDesk>
        </Container>
    );
};

AuthHeader.propTypes = {
};

export default AuthHeader;
