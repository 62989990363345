import { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import PrimaryButton from "../PrimaryButton";
import IcoClose from "../../icons/ico_close_modal.svg";
import FolioImg from "../../icons/img_folio.png";

import Loading from "../../icons/loading.svg";
import "./ModalCancelAppointment.css";
import Swal from "sweetalert2";
import ReactTooltip from "react-tooltip";

// These are private components

// Modal background layer - visible or invisible, but always floating above client's elements
const Model = styled.div`
  z-index: auto;
  display: ${({ show }) => (show ? "block" : "none")};
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(74, 84, 92, 0.6);
`;

// Rendered popup - a positional demo confirmation box
const Container = styled.div`
  position: fixed;
  background: antiquewhite;
  width: 50%;
  min-height: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  padding: 25px 10%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 1rem;
  @media (max-width: 768px) {
    width: 90%;
    padding: 2rem;
    min-height: 400px;
    font-size: 0.8rem;
    margin-top: 30px;
  }
`;

// Rendered popup - a positional demo confirmation box
const ContainerFolio = styled.div`
  position: fixed;
  background: antiquewhite;
  width: 50%;
  min-height: 400px;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 32px;
  padding: 25px 10%;
  background-color: white;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-direction: column;
  box-sizing: border-box;
  font-size: 1rem;
  @media (max-width: 768px) {
    width: 90%;
    padding: 2rem;
    min-height: 400px;
    font-size: 0.8rem;
    margin-top: 30px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  @media (max-width: 767px) {
    align-items: center;
  }
`;

// Rendered popup - a positional demo confirmation box
const ContainerFolioInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: flex-start;
  }
`;

const Header = styled.div`
  font-size: 2rem;
  color: #b10108;
  font-weight: bold;

  @media (max-width: 768px) {
    padding-top: 15px;
    font-size: 1.3rem;
    text-align: center;
  }
`;

const ButtonBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Slot = styled.div`
  font-size: 1.5em;
  color: rgba(103, 109, 114, 1);
  width: 100%auto;
`;

const ContainerInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: left;

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Input = styled.input`
  height: 3.5rem;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 5px #8d8a7d98;
  border: 0.800000011920929px solid #9e9e9f;
  border-radius: 4px;
  opacity: 1;
  font-size: 1.5rem;
  font-family: "Montserrat";
  padding: 0 1rem;
  width: 85%;
  letter-spacing: 2px;
  color: #a6a6af;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Close = styled.span`
  position: absolute;
  top: 15px;
  right: 20px;
  cursor: pointer;
`;

const FolioText = styled.div`
  font-family: "Nutmeg";
  color: #00326c;
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: 1.5rem;

  @media (max-width: 768px) {
    margin-top: 1rem;
  }
`;

const Folio = styled.span`
  background: #efefef;
  border-radius: 11px;
  padding: 20px;
  color: #00326c;
  font-size: 2rem;
  font-weight: bold;
  margin: 2rem 0;

  @media (max-width: 768px) {
    font-size: 1.3rem;
    margin: 1rem 0;
  }
`;

const MessageComplete = styled.div`
  font-family: "Nutmeg";
  color: #676d72;
  font-size: 1.3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;

  @media (max-width: 768px) {
    font-size: 1rem;
  }
`;

const Error = styled.div`
  color: white;
  font-weight: bold;
  background: #b10108;
  padding: 12px;
  margin-bottom: 1.5rem;
`;

const ModalCancelAppointment = (props) => {
  const {
    show, // boolean - visible/invisible
    headerText, // text
    openPos, // symbol for placement
    setShowModal,
    curpValue,
  } = { ...props };

  const [formData, setFormData] = useState({
    curp: curpValue,
    folio: "",
  });
  const { curp, folio } = formData;

  const [errorCurp, setErrorCurp] = useState(null);
  const [errorFolio, setErrorFolio] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [complete, setComplete] = useState(false);
  const [folioCancelacion, setFolioCancelacion] = useState(true);

  const handleForm = ({ target }) => {
    setFormData({
      ...formData,
      [target.name]: target.value.toUpperCase(),
    });
  };

  const handleSubmit = () => {
    if (curp.trim() === "") {
      setErrorCurp("La CURP es requerida");
      return;
    } else if (curp.trim().length !== 18) {
      setErrorCurp("La CURP debe tener 18 caracteres");
      return;
    } else if (curp.trim().length === 18) {
      let validPatternCURP =
        "^([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([A-Z&]|[a-z&]{1})([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([HM]|[hm]{1})([AS|as|BC|bc|BS|bs|CC|cc|CS|cs|CH|ch|CL|cl|CM|cm|DF|df|DG|dg|GT|gt|GR|gr|HG|hg|JC|jc|MC|mc|MN|mn|MS|ms|NT|nt|NL|nl|OC|oc|PL|pl|QT|qt|QR|qr|SP|sp|SL|sl|SR|sr|TC|tc|TS|ts|TL|tl|VZ|vz|YN|yn|ZS|zs|NE|ne]{2})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([^A|a|E|e|I|i|O|o|U|u]{1})([A-Za-z0-9]{1})([0-9]{1})$";
      let result = curp.match(validPatternCURP);
      if (!result) {
        setErrorCurp("El formato de la CURP no es válido");
        return;
      } else {
        setErrorCurp(null);
      }
    }

    if (folio.trim() === "") {
      setErrorFolio("El Folio es requerido");
      return;
    } else if (folio.trim().length < 11 || folio.trim().length > 20) {
      setErrorFolio("El folio debe contener entre 11 y 20 caracteres");
      return;
    } else if (folio.trim().length >= 11 && folio.trim().length <= 20) {
      let validPatternFOLIO = "^VG([0-9&])+(([A-Z&]|[0-9&]){4})$";
      let result = folio.match(validPatternFOLIO);
      if (!result) {
        setErrorFolio("El formato del folio no es válido");
        return;
      } else {
        setErrorFolio(null);
      }
    }

    setSubmit(true);
  };

  const handleTerminate = () => {
    window.location.href = `${process.env.REACT_APP_URL}/`;
  };

  useEffect(() => {
    if (submit) {
      setLoading(true);
      const update = async () => {
        const req = await fetch(
          `${process.env.REACT_APP_API_URL}/registro/${folio}/delete`,
          {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ curp }),
          }
        ).catch(() => {
          setLoading(false);
        });

        const data = await req.json();
        if (data.error) {
          Swal.fire({
            title: "Error!",
            text: data.error_descripcion,
            icon: "error",
            confirmButtonText: "Aceptar",
          }).then(() => {
            // window.location.href = `${process.env.REACT_APP_URL}/`;
          });
          setSubmit(false);
        } else {
          setFolioCancelacion(data.folio_cancelacion);
          setComplete(true);
        }
        setLoading(false);
      };
      update();
    }
  }, [submit]);

  return (
    <Model show={show}>
      {!complete && (
        <ContainerFolio openPos={openPos}>
          <Close onClick={() => setShowModal(false)}>
            <img src={IcoClose} alt="Cerrar" />
          </Close>
          <Header>
            <span>{headerText}</span>
          </Header>
          <ContainerInput>
            <Form autoComplete="off">
              <div>
                {errorCurp && <Error>{errorCurp}</Error>}
                {errorFolio && <Error>{errorFolio}</Error>}
              </div>
              <Input
                type="text"
                placeholder="Ingresa tu CURP*"
                name="curp"
                value={curp}
                onChange={handleForm}
                maxLength="18"
                readOnly={true}
                style={{ marginBottom: "1.5rem" }}
              />
              {/* {errorCurp && <Error>{errorCurp}</Error>} */}
              <ContainerFolioInput>
                <Input
                  type="text"
                  placeholder="Folio de Registro de Cita*"
                  name="folio"
                  value={folio}
                  onChange={handleForm}
                  minLength="11"
                  maxLength="20"
                />
                <a
                  style={{
                    color: "#015DB1",
                    borderRadius: "100%",
                    border: "1px solid #015DB1",
                    padding: "0 6px",
                    fontSize: "15px",
                    cursor: "pointer",
                  }}
                  className="question"
                  data-tip
                  data-for="clickme"
                >
                  ?
                </a>{" "}
                <ReactTooltip
                  place="bottom"
                  type="light"
                  effect="solid"
                  className="tool"
                  id="clickme"
                >
                  {" "}
                  <img src={FolioImg} style={{ width: "100%" }} />
                </ReactTooltip>
              </ContainerFolioInput>

              {/* {errorFolio && <Error>{errorFolio}</Error>} */}
            </Form>
          </ContainerInput>
          <ButtonBar className="submit">
            {!loading && (
              <PrimaryButton primary={true} onClick={handleSubmit}>
                Cancelar mi Cita
              </PrimaryButton>
            )}
            {loading && (
              <span>
                <img src={Loading} alt="Cargando..." />
              </span>
            )}
          </ButtonBar>
        </ContainerFolio>
      )}

      {complete && (
        <Container openPos={openPos}>
          <Header className="header-complete">
            <span>Tu cita ha sido cancelada</span>
          </Header>
          <div>
            <MessageComplete>
            Verifica si las jornadas vigentes son para tu segmento y marca de vacuna. De lo contrario, infórmate en: <a style={{color:"#37aefe", cursor:"pointer"}} href="https://coronavirus.jalisco.gob.mx/ruta-de-la-vacunacion/" target="_blank">gobjal.mx/VacunacionJalisco </a>
            </MessageComplete>
          </div>
          <ButtonBar>
            <PrimaryButton primary={true} onClick={handleTerminate}>
              Finalizar
            </PrimaryButton>
          </ButtonBar>
        </Container>
      )}
    </Model>
  );
};

ModalCancelAppointment.propTypes = {
  show: PropTypes.bool.isRequired,
  headerText: PropTypes.string.isRequired,
  openPos: PropTypes.symbol.isRequired,
};

export default ModalCancelAppointment;
