import { css } from "styled-components";

const styles = {
  header: ({ color }) => css`
    font: normal normal normal 1.5em Nutmeg;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 0px;
    color: ${color};
  `,
  titleText: ({
    color,
    size = 12,
    styleFont = "normal",
    weight = 400,
    marginBottom = 0,
    marginTop = 0,
  }) => css`
    font: normal normal normal 1.5em Nutmeg;
    font-weight: ${weight};
    font-style: ${styleFont};
    font-size: ${size}px;
    margin-bottom: ${marginBottom}px;
    margin-top: ${marginTop}px;
    color: ${color};
    @media (max-width: 780px) {
      font-size: 25px;
    }
  `,
  form: () => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 50%;
    padding: 1em 0;
    @media (max-width: 780px) {
      width: 90%;
    }
    @media (max-width: 1024px && min-width: 781px) {
      width: 75%;
    }
  `,
  formVacunarme: () => css`
    padding: 1em 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    @media (max-width: 780px) {
      width: 90%;
      flex-direction: column;
      align-items: flex-start;
      padding: 1em 0;
    }
    @media (max-width: 1024px && min-width: 781px) {
      width: 75%;
    }
  `,
  container: () => css`
    min-height: 68vh;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 1em;
    padding-bottom: 1em;
    background: #ffffff 0% 0% no-repeat padding-box;
    border-radius: 1em;
    box-shadow: 0px 3px 15px #00000027;
    opacity: 1;
    color: #676d72;
    font-size: 1.25em;
    padding: 1.5em;
    padding-left: 10%;
    padding-right: 10%;
    z-index: 10;
    @media (max-width: 1024px) {
      width: 75%;
    }
    @media (max-width: 780px) {
      width: 75%;
    }
  `,
  stepContainer: () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: baseline;
    width: 100%;
    justify-content: space-between;
    z-index: 10;
    @media (max-width: 780px) {
      width: 90%;
    }
    @media (max-width: 1024px && min-width: 781px) {
      width: 75%;
    }
  `,
  link: () => css`
    text-decoration: none;
    color: #37aefd;
    :hover {
      text-decoration: underline;
      color: #015db1;
    }
  `,
  bottom: () => css`
    text-align: center;
  `,
  curpText: () => css`
    text-align: left;
    font-size: 22px;
    @media (max-width: 780px) {
      font-size: 17px;
    }
  `,
  errortext: () => css`
    text-align: justify;
    margin-top: 1.5em;
  `,
  blueHeader: ({ color, size = 12 }) => css`
    text-align: left;
    color: ${color};
    font-size: ${size}px;
    padding: 5px 0 20px 0;
    @media (max-width: 780px) {
      font-size: 26px;
      padding: 10px 0;
    }
  `,
  divider: () => css`
    width: 100%;
    border: 1px solid #efefef;
  `,
  buttonContainer: () => css`
    margin-left: 20px;
    @media (max-width: 780px) {
      margin-left: 0;
      margin-top: 10px;
    }
  `,
  label: () => css`
    width: 100%;
    font-size: 16px;
    margin-bottom: -15px;
    text-align: left;
  `,
  iconContainer: () => css`
    text-align: left;
  `,
  age: ({
    color = "#00326C",
    size = 12,
    styleFont = "normal",
    weight = 400,
  }) => css`
    color: ${color};
    font-size: ${size}px;
    margin: 0;
    display: flex;
    align-items: center;
    font-style: ${styleFont};
    font-weight: ${weight};
  `,
  subtitle: ({ color = "#00326C", size = 12 }) => css`
    color: ${color};
    font-size: ${size}px;
    width: 100%;
  `,
  dosis: ({ size = 12 }) => css`
    background-color: #37aefe;
    border-radius: 100%;
    color: #fdfdfd;
    padding: 1px 7px;
    font-size: 12px;
    margin-right: 5px;
    font-style: normal !important;
  `,
  icon: () => css`
    width: auto;
    height: 40px;
    margin-right: 1em;
    .a {
      fill: #37aefd;
      stroke: #37aefd;
    }
  `,
};

export default styles;
