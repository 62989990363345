import React, { useEffect, useState } from 'react';
import AuthHeader from '../layout/AuthHeader';
import AuthUser from '../layout/AuthUser';
import SponsorsLogos from '../layout/SponsorsLogos';
import Footer from '../layout/Footer';
import Result from '../components/AppointmentResult';

function Appointment() {
    const [appointment, setAppointment] = useState(null);
    const [curp, setCurp] = useState('');
    const [validation, setValidation] = useState('');
    const [error, setError] = useState('');
    useEffect(() => {
        const curpLS = window.localStorage.getItem('curp');
        const appointmentLS = window.localStorage.getItem('appointment') || null;
        const validationLS = window.localStorage.getItem('validation') || null;
        const errorLS = window.localStorage.getItem('error') || '';
        if (!curpLS) {
            alert('Debe ingresar su CURP primero');
            window.location.href = `${process.env.REACT_APP_URL}`;
        }
        try {
            const localAppointment = JSON.parse(appointmentLS);
            setAppointment(localAppointment);
            const localCurp = JSON.parse(curpLS);
            setCurp(localCurp);
            const localValidation = JSON.parse(validationLS);
            setValidation(localValidation);
            setError(errorLS);
        } catch (ex) {
            window.localStorage.removeItem('appointment');
            window.location.href = `${process.env.REACT_APP_URL}`;
        }

    }, []);
    return (<div className="App-header" style={{ minHeight: '100vh' }}>
        <AuthHeader />
        <div style={{ width: "100%", position: "absolute", top: "120px", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Result appointment={appointment} curp={curp} validation={validation} error={error}/>
            <SponsorsLogos color="#FFFFFF" marginSides="2em" />
            <Footer color={"#00326C"} />
        </div>
    </div>);
}

export default Appointment;