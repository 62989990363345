import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Styles from './FormElement.styles'

const Container = styled.div`
  ${Styles.container}
`
const RadioGroup = styled.div`
  ${Styles.radioGroup}
`
const Caption = styled.div`
  ${Styles.caption}
`
const Input = styled.input.attrs({
  type: 'input'
})`
  ${Styles.textbox}
`
const NumberInput = styled.input.attrs({
  type: 'number'
})`
  ${Styles.textbox}
`
const DateInput = styled.input.attrs({
  type: 'date'
})`
  ${Styles.textbox};
  font-size:1em;
`

const Select = styled.select`
  ${Styles.select}
`
const Option = styled.option`
  ${Styles.option}
`
const RadioOption = styled.input.attrs({
  type: 'radio'
})`
  ${Styles.radio}
`
const RadioLabel = styled.label`
  ${Styles.radioLabel}
`
const Label = styled.label`
  ${Styles.label}
`
const JaliscoCaption = styled.div`
  ${Styles.jaliscoCaption}
`
const JaliscoRadioGroup = styled.div`
  ${Styles.jaliscoRadioGroup}
`
const JaliscoRadioLabel = styled.label`
  ${Styles.jaliscoRadioLabel}
`

const TextInput = ({ caption, onChange, value, disabled, optional, type, ...rest }) => {
  let InputComponent;
  if(type === 'number'){
    InputComponent = <NumberInput
      type
      disabled={disabled}
      value={value}
      onChange={onChange}
    />;
  }else if(type==='date'){
    InputComponent = <DateInput
      type
      disabled={disabled}
      value={value}
      onChange={onChange}
      min={rest.minDate}
      max={rest.maxDate}
    />
  }else{
    InputComponent = <Input
      type
      disabled={disabled}
      value={value}
      onChange={onChange}
    />
  }
  return (
    <Container>
      <Caption>
        {caption}
        {optional && (
          <span style={{ fontWeight: 350, marginLeft: '0.7em' }}>
            (Opcional)
          </span>
        )}
      </Caption>
      {InputComponent}
    </Container>
  )
}

const SelectInput = ({
  caption,
  onChange,
  options,
  selected,
  disabled,
  optional
}) => {
  return (
    <Container>
      <Caption>
        {caption}
        {optional && (
          <span style={{ fontWeight: 350, marginLeft: '0.7em' }}>
            (Opcional)
          </span>
        )}
      </Caption>
      <Select disabled={disabled} value={selected} onChange={onChange}>
        {options.map((option, idx) => {
          return (
            <Option
              selected={option.selected}
              key={`select-${idx}`}
              value={option.value}
            >
              {option.caption}
            </Option>
          )
        })}
      </Select>
    </Container>
  )
}

const LargeSelectInput = ({
  caption,
  onChange,
  options,
  selected,
  disabled,
  optional
}) => {
  return (
    <Container style={{marginTop:'1em', marginRight:'0'}}>
      {caption && <Caption>
        {caption}
        {optional && (
          <span style={{ fontWeight: 350, marginLeft: '0.7em' }}>
            (Opcional)
          </span>
        )}
      </Caption>}
      <Select style={{fontSize:'0.8em', height:'3em', backgroundColor:'#ffffff', color:'#99A0A3', fontFamily:'Nutmeg'}} disabled={disabled} value={selected} onChange={onChange}>
        {options.map((option, idx) => {
          return (
            <Option
              selected={option.selected}
              key={`select-${idx}`}
              value={option.value}
            >
              {option.caption}
            </Option>
          )
        })}
      </Select>
    </Container>
  )
}

const RadioInput = ({ onChange, options, selected, groupName }) => {
  return (
    <RadioGroup>
      {options.map((option, idx) => {
        return (
          <RadioLabel key={`radioInput-groupName-${idx}`}>
            <RadioOption
              key={option.value}
              onChange={onChange}
              value={option.value}
              name={groupName}
              checked={selected === parseInt(option.value)}
            />
            {option.caption}
          </RadioLabel>
        )
      })}
    </RadioGroup>
  )
}

const JaliscoRadioInput = ({
  onChange,
  options,
  selected,
  groupName,
  caption
}) => {
  return (
    <Container style={{ marginRight: '10em' }}>
      <JaliscoCaption>{caption}</JaliscoCaption>
      <div style={{ marginTop: '10px' }}></div>
      <JaliscoRadioGroup>
        {options.map((option, idx) => {
          return (
            <JaliscoRadioLabel key={`radioInput-groupName-${idx}`}>
              <RadioOption
                key={option.value}
                onChange={onChange}
                value={option.value}
                name={groupName}
                checked={selected === parseInt(option.value)}
              />
              {option.caption}
            </JaliscoRadioLabel>
          )
        })}
      </JaliscoRadioGroup>
    </Container>
  )
}

const HomeRadioInput = ({
  onChange,
  options,
  selected,
  groupName,
  caption
}) => {
  return (
    <Container style={{ marginRight: '0', marginTop:'0', padding:'10px 0' }}>
      {caption && <JaliscoCaption>{caption}</JaliscoCaption>}
      {caption && <div style={{ marginTop: '10px' }}></div>}
      <JaliscoRadioGroup style={{alignItems:'center', justifyContent:'center'}}>
        {options.map((option, idx) => {
          return (
            <JaliscoRadioLabel key={`radioInput-groupName-${idx}`}>
              <RadioOption
                key={option.value}
                onChange={onChange}
                value={option.value}
                name={groupName}
                checked={selected === parseInt(option.value)}
              />
              {option.caption}
            </JaliscoRadioLabel>
          )
        })}
      </JaliscoRadioGroup>
    </Container>
  )
}

const Icon = styled.svg`
  fill: none;
  stroke: ${({ checked }) => (checked ? 'white' : '#BAC0C3')};
  stroke-width: 4px;
`

const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  background: white;
  border: ${({ checked }) => (checked ? '#4597EA' : '#656572')} 3px solid;
  border-radius: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #eaedef;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  background-color: ${({ checked }) => (checked ? '#4597EA' : 'white')};
`

const JaliscoStyledCheckbox = styled.div`
  background: white;
  border: ${({ checked }) => (checked ? '#4597EA' : '#656572')} 3px solid;
  border-radius: 2px;
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  transition: all 150ms;
  margin-top: 2.3em;
  margin-left: 1em;

  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px #eaedef;
  }

  ${Icon} {
    visibility: ${({ checked }) => (checked ? 'visible' : 'hidden')};
  }

  background-color: ${({ checked }) => (checked ? '#4597EA' : 'white')};
`

const CheckboxInput = ({ label, image, onChange, checked, disabled }) => (
  <Container style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
    <Label>
      <HiddenCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <StyledCheckbox disabled={disabled} checked={checked}>
        <Icon checked={checked} viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </StyledCheckbox>
      <span style={{ margin: '5px', color:'#656572' }}>{label}</span>
    </Label>
  </Container>
)

const JaliscoCheckboxInput = ({
  label,
  image,
  onChange,
  checked,
  disabled
}) => (
  <Container style={{ marginTop: '1.3em' }}>
    <JaliscoCaption>{label}</JaliscoCaption>
    <Label>
      <HiddenCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <JaliscoStyledCheckbox disabled={disabled} checked={checked}>
        <Icon checked={checked} viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </JaliscoStyledCheckbox>
      {image && <img alt='icono accesibilidad' src={image} />}
    </Label>
  </Container>
)

const JaliscoCheckboxInputOtra = ({
  label,
  image,
  onChange,
  checked,
  disabled,
  inputOnChange,
  inputValue
}) => (
  <Container style={{ marginTop: '1.3em', marginBottom: '3em' }}>
    <JaliscoCaption>{label}</JaliscoCaption>
    <Label>
      <HiddenCheckbox
        disabled={disabled}
        checked={checked}
        onChange={onChange}
      />
      <JaliscoStyledCheckbox disabled={disabled} checked={checked}>
        <Icon checked={checked} viewBox='0 0 24 24'>
          <polyline points='20 6 9 17 4 12' />
        </Icon>
      </JaliscoStyledCheckbox>
      {image && <img alt='icono accesibilidad' src={image} />}
    </Label>
    {checked && (
      <TextInput
        caption='Describa aquí'
        onChange={inputOnChange}
        value={inputValue}
      />
    )}
  </Container>
)

TextInput.propTypes = {
  caption: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.string,
  disabled: PropTypes.bool
}

SelectInput.propTypes = {
  options: PropTypes.array.isRequired,
  caption: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  selected: PropTypes.number,
  disabled: PropTypes.bool
}

RadioInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  selected: PropTypes.number,
  groupName: PropTypes.string.isRequired
}

CheckboxInput.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  image: PropTypes.string,
  disabled: PropTypes.bool
}

const FormElements = {
  TextInput,
  SelectInput,
  CheckboxInput,
  RadioInput,
  HomeRadioInput,
  JaliscoRadioInput,
  JaliscoCheckboxInput,
  JaliscoCheckboxInputOtra
}
export default FormElements
export {
  TextInput,
  SelectInput,
  LargeSelectInput,
  CheckboxInput,
  RadioInput,
  HomeRadioInput,
  JaliscoRadioInput,
  JaliscoCheckboxInput,
  JaliscoCheckboxInputOtra
}
